import { FORMAT_TIME } from '@/constants'
import { Organisation, OrganisationTypeEnum } from '@/types'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

type Props = {
  organisations: Organisation[]
}

type GroupData = {
  week: string
  expertsArr: Organisation[]
  sponsorsArr: Organisation[]
}

const Registered: React.FC<Props> = ({ organisations }) => {
  const [data, setData] = useState<GroupData[]>([])

  useEffect(() => {
    const groupData: { [key: string]: GroupData } = {}
    const getWeek = (datetime: string) => moment(datetime).format(FORMAT_TIME.YEAR_WEEK)
    organisations.forEach(org => {
      const week = getWeek(org.createdAt)
      if (!groupData[week]) {
        groupData[week] = { week, expertsArr: [], sponsorsArr: [] }
      }
      if (org.type === OrganisationTypeEnum.EXPERT) {
        groupData[week].expertsArr.push(org)
      } else if (org.type === OrganisationTypeEnum.SPONSOR) {
        groupData[week].sponsorsArr.push(org)
      }
    })
    setData(Object.values(groupData))
  }, [organisations])

  return (
    <div className='bg-white rounded-lg p-4'>
      <div className='text-center text-lg font-medium'>Expert / Sponsor registered</div>
      {data.length && (
        <ReactApexChart
          type='bar'
          options={{
            colors: ['#43aa8b', '#ff99c8'],
            chart: {
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            legend: {
              show: true,
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: data.map(item => item.week),
            },
          }}
          series={[
            {
              name: 'Expert',
              data: data.map(item => item.expertsArr.length),
            },
            {
              name: 'Sponsor',
              data: data.map(item => item.sponsorsArr.length),
            },
          ]}
        />
      )}
    </div>
  )
}

export default React.memo(Registered)
