import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import TableCommissionTerms from '@/pages/CommissionTerms/components/TableCommissionTerms'
import { CommissionTerms } from '@/types'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
  data: CommissionTerms[]
}

const ViewCommissionTermsDialog: React.FC<Props> = ({ open, onClose, data }) => {
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='List Commission Terms'
      size='lg'
      ButtonPositive={<AppButton onClick={onClose}>Close</AppButton>}
    >
      <TableCommissionTerms data={data} />
    </AppDialog>
  )
}

export default React.memo(ViewCommissionTermsDialog)
