import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import React, { useState } from 'react'
import ChatDetails from './components/ChatDetails'
import ChatboxItem from './components/ChatboxItem'

const listChatboxes = [
  {
    id: 1,
    interlocutor: {
      id: 2,
      name: 'User 2',
      avatar: '',
    },
    last_message: {
      id: 1,
      content: 'This is a message from myself test test test',
      timeStamp: '2024-05-30T10:00:00Z',
      sender: {
        id: 6,
        name: 'Expert 3',
        avatar: '',
      },
    },
  },
  {
    id: 2,
    interlocutor: {
      id: 3,
      name: 'User 3',
      avatar: '',
    },
    last_message: {
      id: 1,
      content: 'This is a message from user3 test test test',
      timeStamp: '2024-05-30T10:00:00Z',
      sender: {
        id: 3,
        name: 'User 3',
        avatar: '',
      },
    },
  },
]

const Conversations: React.FC = () => {
  const [selectedChatbox, setSelectedChatbox] = useState<any>(null)

  const selectChatbox = (chatbox: any) => {
    setSelectedChatbox(chatbox)
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Conversations' />

      <SideBar />

      <div className='content-conversations md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 flex flex-row rounded-md bg-white'>
          <div className='w-96 border-r'>
            <div className='text-2xl font-semibold p-2'>Chat</div>
            {listChatboxes.map(chatbox => (
              <ChatboxItem
                key={chatbox.id}
                chatbox={chatbox}
                selectChatbox={selectChatbox}
                selectedChatbox={selectedChatbox}
              />
            ))}
          </div>
          <div className='w-full'>
            {selectedChatbox && <ChatDetails selectedChatbox={selectedChatbox} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Conversations
