import SideBar from '@/components/SideBar'
import Tooltip from '@/components/Tooltip'
import TopBar from '@/components/TopBar'
import useAccount from '@/hooks/useAccount'
import useExperts from '@/hooks/useExperts'
import { User } from '@/types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import UserInfoDialog from './components/ExpertInfoDialog'

const Experts: React.FC = () => {
  const { getListExperts } = useExperts()
  const { getUserById } = useAccount()

  const [data, setData] = useState<User[]>([])
  const [expertInfo, setExpertInfo] = useState<User | undefined>(undefined)

  useEffect(() => {
    getListExperts({
      onSuccess: response => {
        setData(response.experts)
      },
    })
  }, [])

  const onViewExpert = (id: number) => () => {
    getUserById({
      variables: { userId: id },
      onSuccess: response => {
        setExpertInfo(response.user)
      },
    })
  }

  const onCloseDialog = () => {
    setExpertInfo(undefined)
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Connections' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <div className='text-2xl font-semibold mb-6'>List Experts</div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow sx={{ background: '#F7F7F7' }}>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Organisation</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Occupations</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length ? (
                  data.map((item: any, index: number) => (
                    <TableRow key={item.id}>
                      {/* <TableCell>{(page - 1) * DEFAULT_PAGESIZE + index + 1}</TableCell> */}
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.organisation.name}</TableCell>
                      <TableCell>{item.bio?.occupations[0]?.title}</TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' justifyContent='end' spacing={1}>
                          <Tooltip title='View'>
                            <VisibilityOutlinedIcon
                              onClick={onViewExpert(item.id)}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <div className='flex w-full flex-col items-center py-14'>
                        <label className='font-medium'>No data found</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <UserInfoDialog
          open={!!expertInfo}
          onClose={onCloseDialog}
          expertInfo={expertInfo as User}
        />
      </div>
    </div>
  )
}

export default Experts
