import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import { Contract } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onUpdate: (unitRate: number, description?: string) => void
  contract: Contract
}

const SetCashCompensationDialog: React.FC<Props> = ({ open, onClose, onUpdate, contract }) => {
  const refUnitRate = useRef<AppInputRef | null>(null)
  const refDescription = useRef<AppInputRef | null>(null)

  const handleUpdate = () => {
    const unitRate = refUnitRate.current?.getValue() ? Number(refUnitRate.current?.getValue()) : 0
    const description = refDescription.current?.getValue()

    if (!unitRate) {
      !unitRate && refUnitRate.current?.setError('Please enter Unit Rate')
    } else {
      onUpdate(unitRate, description)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Set Cash Compensation'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleUpdate}>Set</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppInput
          label='Unit Rate'
          type='number'
          required
          defaultValue={
            contract.cashCompensation?.unitRate ? contract.cashCompensation.unitRate.toString() : ''
          }
          ref={refUnitRate}
        />

        <AppInput
          label='Description'
          multiline
          defaultValue={contract.cashCompensation?.description}
          ref={refDescription}
        />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(SetCashCompensationDialog)
