import { RootState } from '@/redux/store'
import { OrganisationTypeEnum } from '@/types'
import { useSelector } from 'react-redux'

const useOrganisationType = () => {
  const { profile } = useSelector((state: RootState) => state.common)

  const isPlatform = profile?.organisation?.type === OrganisationTypeEnum.PLATFORM
  const isExpert = profile?.organisation?.type === OrganisationTypeEnum.EXPERT
  const isSponsor = profile?.organisation?.type === OrganisationTypeEnum.SPONSOR

  return { isPlatform, isExpert, isSponsor }
}

export default useOrganisationType
