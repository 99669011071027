import Button from '@mui/material/Button'
import React, { ReactNode } from 'react'

type Props = {
  variant?: 'contained' | 'text' | 'outlined'
  color?: 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error'
  size?: 'small' | 'medium' | 'large'
  startIcon?: ReactNode
  endIcon?: ReactNode
  children: string | ReactNode
  onClick: () => void
  block?: boolean
  disabled?: boolean
}

const AppButton: React.FC<Props> = ({
  variant = 'contained',
  color = 'primary',
  size = 'medium',
  startIcon,
  endIcon,
  children,
  onClick,
  block,
  disabled,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      disableElevation
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={block}
      sx={{ textTransform: 'capitalize', minWidth: 120 }}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

export default React.memo(AppButton)
