import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import { FORMAT_TIME } from '@/constants'
import useAccount from '@/hooks/useAccount'
import { PendingTask } from '@/types'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  task: PendingTask
}

const UserInvitationDialog: React.FC<Props> = ({ open, onClose, task }) => {
  const { getUserById } = useAccount()
  const [email, setEmail] = useState('')

  useEffect(() => {
    if (task?.referenceId) {
      getUserById({
        variables: { userId: task.referenceId },
        onSuccess: response => {
          setEmail(response.user?.email || '')
        },
      })
    }
  }, [task])

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='User Invitation'
      size='xs'
      ButtonPositive={<AppButton onClick={onClose}>Close</AppButton>}
    >
      <div className='grid grid-cols-2'>
        <div>User email:</div>
        <div className='font-medium text-primary'>{email}</div>
        <div>Invitation send at:</div>
        <div>
          {task?.taskCreated ? moment(task.taskCreated).format(FORMAT_TIME.YEAR_MONTH_DAY) : ''}
        </div>
      </div>
    </AppDialog>
  )
}

export default React.memo(UserInvitationDialog)
