import { gql } from '@apollo/client'

export const GET_PROFILE = gql`
  query GetProfile {
    profile {
      id
      email
      name
      role
      organisation {
        id
        name
        type
      }
      bio {
        id
        occupations {
          id
          title
          position
          company
          country
          fromDate
          toDate
          summary
        }
      }
    }
  }
`

export const GET_PLATFORM_DASHBOARD = gql`
  query GetDashboard {
    dashboard {
      pendingTasks
      pendingTasksArr {
        owner
        check
        partnerOrganisation {
          id
          name
        }
        taskCreated
        taskUpdated
        referenceId
      }
      contracts
      contractsArr {
        id
        status
        createdAt
      }
      timesheetsArr {
        id
        createdAt
        status
      }
      organisations
      organisationsArr {
        id
        name
        type
        createdAt
      }
    }
  }
`

export const GET_EXPERT_DASHBOARD = gql`
  query GetDashboard {
    dashboard {
      pendingTasksArr {
        owner
        check
        partnerOrganisation {
          id
          name
        }
        taskCreated
        taskUpdated
      }
      contractsArr {
        id
        status
        createdAt
      }
      timesheetsArr {
        id
        createdAt
        status
      }
    }
  }
`

export const GET_SPONSOR_DASHBOARD = gql`
  query GetDashboard {
    dashboard {
      pendingTasksArr {
        owner
        check
        partnerOrganisation {
          id
          name
        }
        taskCreated
        taskUpdated
      }
      contractsArr {
        id
        status
        createdAt
      }
      timesheetsArr {
        id
        createdAt
        status
      }
    }
  }
`

export const GET_COMMISSION_TERMS = gql`
  query GetCommissionTerms {
    commissionTerms {
      id
      partnerSignature {
        id
        name
      }
      commission
      frequencyType
      status
      createdAt
      updatedAt
      connection {
        organisation {
          name
        }
      }
    }
  }
`

export const GET_CONNECTIONS = gql`
  query GetConnections {
    connections {
      id
      organisation {
        id
        name
        type
      }
      commissionTerms {
        id
        partnerSignature {
          id
          name
        }
        commission
        frequencyType
        status
        createdAt
        updatedAt
        connection {
          organisation {
            name
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_CONTRACTS = gql`
  query GetContracts {
    contracts {
      id
      status
      units
      billingTimeUnit
      paymentTerms
      createdAt
      updatedAt
      expertUser {
        id
        name
        email
        role
      }
      expertOrganisation {
        id
        name
        type
      }
      sponsorOrganisation {
        id
        name
      }
      expertCommissionTerms {
        id
      }
      sponsorCommissionTerms {
        id
      }
      expertSigned
      sponsorSigned
      startDate
      endDate
      sopCompensation {
        unitRate
        type
        description
      }
      cashCompensation {
        unitRate
        description
      }
    }
  }
`

export const GET_DETAIL_CONTRACT = gql`
  query GetDetailContract($contractId: Float!) {
    contract(contractId: $contractId) {
      id
      sponsorOrganisation {
        id
        name
      }
      expertOrganisation {
        id
        name
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GetUserById($userId: Float!) {
    user(userId: $userId) {
      id
      email
      name
      role
      organisation {
        id
        name
        type
      }
      bio {
        id
        occupations {
          id
          title
          position
          company
          country
          fromDate
          toDate
          summary
        }
      }
    }
  }
`

export const GET_EXPERTS = gql`
  query GetExperts {
    experts {
      id
      email
      name
      organisation {
        id
        name
        type
      }
      bio {
        id
        occupations {
          id
          title
        }
      }
    }
  }
`

export const GET_PENDING_TASKS = gql`
  query GetPendingTasks {
    dashboard {
      pendingTasks
      pendingTasksArr {
        owner
        check
        partnerOrganisation {
          id
          name
        }
        taskCreated
        taskUpdated
        referenceId
      }
    }
  }
`

export const GET_TIMESHEETS = gql`
  query GetTimesheets {
    timesheets {
      id
      createdAt
      updatedAt
      deliveryDate
      units
      status
      summary
      contract {
        id
      }
      expertUser {
        name
        organisation {
          name
        }
      }
    }
  }
`
