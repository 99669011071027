import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import AppSelect, { AppSelectRef } from '@/components/AppSelect'
import { CreateCommissionTermsParams } from '@/graphql/types'
import { CommissionTermsFrequencyTypeEnum, Connection } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onCreate: (params: CreateCommissionTermsParams) => void
  listConnections: Connection[]
  connectionId?: number
}

const CreateCommissionTermsDialog: React.FC<Props> = ({
  open,
  onClose,
  onCreate,
  listConnections,
  connectionId,
}) => {
  const refConnection = useRef<AppSelectRef | null>(null)
  const refFrequencyType = useRef<AppSelectRef | null>(null)
  const refCommission = useRef<AppInputRef | null>(null)

  const handleCreateCommissionTerms = () => {
    const connectionId = refConnection.current?.getValue()
      ? Number(refConnection.current?.getValue())
      : 0
    const frequencyType = refFrequencyType.current?.getValue() as CommissionTermsFrequencyTypeEnum
    const commission = refCommission.current?.getValue()
      ? Number(refCommission.current?.getValue())
      : 0

    if (!connectionId || !frequencyType || !commission) {
      !connectionId && refConnection.current?.setError('Please select Connection')
      !frequencyType && refFrequencyType.current?.setError('Please select Frequency Type')
      !commission && refCommission.current?.setError('Please enter Commission')
    } else {
      const params: CreateCommissionTermsParams = {
        connectionId,
        frequencyType,
        commission,
      }
      onCreate(params)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Create Commission Terms'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleCreateCommissionTerms}>Create</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppSelect
          defaultValue={connectionId?.toString()}
          list={listConnections.map(item => ({
            value: item.id,
            label: item.organisation?.name,
          }))}
          label='Connection'
          required
          ref={refConnection}
        />

        <AppSelect
          list={[
            { value: CommissionTermsFrequencyTypeEnum.ONE_TIME, label: 'One time' },
            { value: CommissionTermsFrequencyTypeEnum.PER_CONTRACT, label: 'Per contract' },
            { value: CommissionTermsFrequencyTypeEnum.PER_TRANSACTION, label: 'Per transaction' },
            { value: CommissionTermsFrequencyTypeEnum.FIVE_CONTRACTS, label: 'Five contracts' },
          ]}
          label='Frequency Type'
          required
          ref={refFrequencyType}
        />

        <AppInput label='Commission' type='number' required ref={refCommission} />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(CreateCommissionTermsDialog)
