import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import {
  GET_EXPERT_DASHBOARD,
  GET_PLATFORM_DASHBOARD,
  GET_SPONSOR_DASHBOARD,
} from '@/graphql/queries'
import { GetDashboardResponse } from '@/graphql/types'

const useDashboard = () => {
  const [getPlatformDashboard] = useCustomLazyQuery<GetDashboardResponse>({
    query: GET_PLATFORM_DASHBOARD,
  })
  const [getExpertDashboard] = useCustomLazyQuery<GetDashboardResponse>({
    query: GET_EXPERT_DASHBOARD,
  })
  const [getSponsorDashboard] = useCustomLazyQuery<GetDashboardResponse>({
    query: GET_SPONSOR_DASHBOARD,
  })

  return { getPlatformDashboard, getExpertDashboard, getSponsorDashboard }
}

export default useDashboard
