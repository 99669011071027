import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import { GET_PENDING_TASKS } from '@/graphql/queries'
import { GetPendingTaskResponse } from '@/graphql/types'

const usePendingTasks = () => {
  const [getListPendingTasks, { refetch }] = useCustomLazyQuery<GetPendingTaskResponse>({
    query: GET_PENDING_TASKS,
  })

  return { getListPendingTasks, refetch }
}

export default usePendingTasks
