import AppButton from '@/components/AppButton'
import AppDatePicker, { AppDatePickerRef } from '@/components/AppDatePicker'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import AppSelect, { AppSelectRef } from '@/components/AppSelect'
import { CreateTimesheetParams } from '@/graphql/types'
import { generateRandomString } from '@/helpers'
import useContracts from '@/hooks/useContracts'
import useOrganisationType from '@/hooks/useOrganisationType'
import { Contract } from '@/types'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import Stack from '@mui/material/Stack'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onCreate: (params: CreateTimesheetParams) => void
}

const CreateTimesheetDialog: React.FC<Props> = ({ open, onClose, onCreate }) => {
  const { getListContracts } = useContracts()
  const { isExpert } = useOrganisationType()

  const [listContracts, setListContracts] = useState<Contract[]>([])
  const [summaries, setSummaries] = useState<{ id: string }[]>([])
  const [errorSummary, setErrorSummary] = useState('')
  const [contractStartDate, setContractStartDate] = useState<string>('')

  const refContract = useRef<AppSelectRef | null>(null)
  const refDeliveryDate = useRef<AppDatePickerRef | null>(null)
  const refUnits = useRef<AppInputRef | null>(null)
  const refSummaries = useRef<Array<AppInputRef | null>>([])

  useEffect(() => {
    if (isExpert) {
      getListContracts({
        onSuccess: response => {
          setListContracts(response.contracts)
        },
      })
    }
  }, [])

  const onSelectContract = (value: string) => {
    const contract = listContracts.find(item => item.id.toString() === value)
    if (contract) {
      setContractStartDate(contract.startDate)
    }
  }

  const addSummary = () => {
    setSummaries([...summaries, { id: generateRandomString() }])
    setErrorSummary('')
  }

  const removeSummary = (id: string) => () => {
    const _summaries = summaries.filter(item => item.id !== id)
    setSummaries(_summaries)
  }

  const handleCreateTimesheet = () => {
    const contractId = refContract.current?.getValue() ? Number(refContract.current?.getValue()) : 0
    const units = refUnits.current?.getValue() ? Number(refUnits.current?.getValue()) : undefined
    const deliveryDate = refDeliveryDate.current?.getValue() || ''
    const listTask = refSummaries.current.map(item => item?.getValue()).filter(item => item)

    let error = false
    refSummaries.current.map(item => {
      if (item) {
        const value = item.getValue()
        if (!value || (value && !value.trim())) {
          item.setError('Please enter Summary')
          error = true
        }
      }
    })

    if (!contractId || !units || !deliveryDate || !listTask.length || error) {
      !contractId && refContract.current?.setError('Please select Contract')
      !units && refUnits.current?.setError('Please enter Units')
      !deliveryDate && refDeliveryDate.current?.setError('Please select Delivery Date')
      !listTask.length && setErrorSummary('Please add at least 1 summary')
    } else {
      const params: CreateTimesheetParams = {
        contractId,
        units,
        deliveryDate,
        summary: {
          // @ts-ignore
          tasks: listTask,
        },
      }
      onCreate(params)
    }
  }

  const handleClose = () => {
    setSummaries([])
    setContractStartDate('')
    onClose()
  }

  return (
    <AppDialog
      open={open}
      onClose={handleClose}
      title='Create Timesheet'
      ButtonNegative={
        <AppButton onClick={handleClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleCreateTimesheet}>Create</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppSelect
          list={listContracts.map(item => ({
            value: item.id,
            label: `${item.sponsorOrganisation?.name} - ${item.billingTimeUnit} - ${item.units}`,
          }))}
          label='Contract'
          onChange={onSelectContract}
          required
          ref={refContract}
        />

        <AppDatePicker
          label='Delivery Date'
          disabled={!contractStartDate}
          disableFuture
          shouldDisableDate={date => date.isBefore(contractStartDate, 'day')}
          required
          ref={refDeliveryDate}
        />

        <AppInput label='Units' type='number' ref={refUnits} />

        <div>
          <div>
            Summary <AddCircleOutlineIcon onClick={addSummary} className='cursor-pointer' />
          </div>
          {!!errorSummary && <div className='text-xs text-rose-600'>{errorSummary}</div>}
        </div>
        <div className='max-h-56 overflow-auto'>
          {summaries.map((item, index) => (
            <div key={item.id} className='flex flex-row items-center gap-2 mb-2'>
              <AppInput ref={ref => (refSummaries.current[index] = ref)} />

              <DeleteIcon
                onClick={removeSummary(item.id)}
                color='error'
                className='cursor-pointer'
              />
            </div>
          ))}
        </div>
      </Stack>
    </AppDialog>
  )
}

export default React.memo(CreateTimesheetDialog)
