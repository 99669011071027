import React, { forwardRef, useImperativeHandle, useState } from 'react'

type Props = {
  label?: string
  placeholder?: string
  styleLabel?: string
  required?: boolean
  maxLength?: number
  type?: string
  onKeyDown?: (event: React.KeyboardEvent) => void
}

export type InputTextRef = {
  clear: () => void
  getValue: () => string | undefined
  setError: (msg: string) => void
}

const InputText: React.ForwardRefRenderFunction<InputTextRef, Props> = (props, ref) => {
  const { label, placeholder, styleLabel, maxLength, required, type, onKeyDown } = props
  const [search, setSearch] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        setSearch('')
      },
      getValue: () => search || undefined,
      setError: (msg: string) => {
        setErrorMessage(msg)
      },
    }),
    [search],
  )

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setErrorMessage('')
  }

  return (
    <div className='flex flex-col items-start mb-4'>
      {!!label && (
        <label className={`text-sm font-medium ${styleLabel} ${required ? 'mandatory-field' : ''}`}>
          {label}
        </label>
      )}

      <div className='flex h-11 w-full flex-row items-center bg-white rounded-lg border common-shadow'>
        <input
          className='w-full h-full px-3 bg-transparent focus:outline-none disabled:bg-[#f7f7f7]'
          placeholder={placeholder || label}
          value={search}
          onChange={onChangeSearch}
          maxLength={maxLength || 255}
          type={type}
          onKeyDown={onKeyDown}
        />
      </div>
      {!!errorMessage && (
        <label className='ml-3 mt-0.5 inline-block text-xs font-normal text-red-400'>
          {errorMessage}
        </label>
      )}
    </div>
  )
}

export default React.memo(forwardRef(InputText))
