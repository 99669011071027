import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import Tooltip from '@/components/Tooltip'
import { FORMAT_TIME } from '@/constants'
import { UpdateCommissionTermsParams } from '@/graphql/types'
import useCommissionTerms from '@/hooks/useCommissionTerms'
import useOrganisationType from '@/hooks/useOrganisationType'
import { showAlert } from '@/redux/reducers/common.slice'
import {
  CommissionTerms,
  CommissionTermsFrequencyTypeEnum,
  CommissionTermsStatusEnum,
} from '@/types'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DoneIcon from '@mui/icons-material/Done'
import DrawIcon from '@mui/icons-material/Draw'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import FrequencyType from './FrequencyType'
import Status from './Status'
import UpdateCommissionTermsDialog from './UpdateCommissionTermsDialog'

type Props = {
  data: CommissionTerms[]
  onRefresh?: () => void
}

const TableCommissionTerms: React.FC<Props> = ({ data, onRefresh }) => {
  const { updateCommissionTerms, signComissionTerms, activateComissionTerms } = useCommissionTerms()

  const dispatch = useDispatch()
  const { isPlatform } = useOrganisationType()

  const [selectedItem, setSelectedItem] = useState<CommissionTerms | undefined>(undefined)
  const [termsId, setTermsId] = useState<number | undefined>(undefined)
  const [mode, setMode] = useState<'sign' | 'activate' | undefined>(undefined)

  const onCloseUpdateDialog = () => {
    setSelectedItem(undefined)
  }

  const onCloseDialog = () => {
    setTermsId(undefined)
    setMode(undefined)
  }

  const onSelectCommissionTerms = (item: CommissionTerms) => () => {
    setSelectedItem(item)
  }

  const onUpdateCommissionTerms = (
    frequencyType: CommissionTermsFrequencyTypeEnum,
    commission: number,
  ) => {
    if (selectedItem?.id) {
      const params: UpdateCommissionTermsParams = {
        termsId: selectedItem.id,
        frequencyType,
        commission,
      }
      updateCommissionTerms({
        variables: params,
        onSuccess: () => {
          onCloseUpdateDialog()
          dispatch(showAlert({ message: 'Update Commission Terms successfully', type: 'success' }))
          onRefresh && onRefresh()
        },
      })
    }
  }

  const onSignCommissionTerms = () => {
    if (termsId) {
      signComissionTerms({
        variables: { commissionTermsId: termsId },
        onSuccess: () => {
          onCloseDialog()
          dispatch(showAlert({ message: 'Sign Commission Terms successfully', type: 'success' }))
          onRefresh && onRefresh()
        },
      })
    }
  }

  const onActivateCommissionTerms = () => {
    if (termsId) {
      activateComissionTerms({
        variables: { commissionTermsId: termsId },
        onSuccess: () => {
          onCloseDialog()
          dispatch(
            showAlert({ message: 'Activate Commission Terms successfully', type: 'success' }),
          )
          onRefresh && onRefresh()
        },
      })
    }
  }

  return (
    <div>
      <TableContainer component={Paper} sx={{}}>
        <Table sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow sx={{ background: '#F7F7F7' }}>
              <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
              {isPlatform && <TableCell sx={{ fontWeight: 600, py: 1 }}>Partner</TableCell>}
              <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                Commission
              </TableCell>
              <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                Created Date
              </TableCell>
              <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                Updated Date
              </TableCell>
              <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                Frequency Type
              </TableCell>
              <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                Status
              </TableCell>
              {onRefresh && (
                <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>{index + 1}</TableCell>
                  {isPlatform && <TableCell>{item.connection?.organisation?.name}</TableCell>}
                  <TableCell align='right'>{item.commission}%</TableCell>
                  <TableCell align='right'>
                    {moment(item.createdAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                  </TableCell>
                  <TableCell align='right'>
                    {moment(item.updatedAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                  </TableCell>
                  <TableCell>
                    <div className='flex justify-center'>
                      <FrequencyType frequencyType={item.frequencyType} />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='flex justify-center'>
                      <Status status={item.status} />
                    </div>
                  </TableCell>
                  {onRefresh && (
                    <TableCell align='right'>
                      <Stack direction='row' justifyContent='end' spacing={1}>
                        {((item.status === CommissionTermsStatusEnum.CREATED && isPlatform) ||
                          (item.status === CommissionTermsStatusEnum.PLATFORM_SIGNED &&
                            !isPlatform)) && (
                          <Tooltip title='Sign'>
                            <DrawIcon
                              onClick={() => {
                                setTermsId(item.id)
                                setMode('sign')
                              }}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        )}
                        {item.status === CommissionTermsStatusEnum.SIGNED && isPlatform && (
                          <Tooltip title='Activate'>
                            <DoneIcon
                              onClick={() => {
                                setTermsId(item.id)
                                setMode('activate')
                              }}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        )}
                        {item.status !== CommissionTermsStatusEnum.INACTIVE &&
                          item.status !== CommissionTermsStatusEnum.EXPIRED &&
                          item.status !== CommissionTermsStatusEnum.ACTIVE &&
                          isPlatform && (
                            <Tooltip title='Update'>
                              <BorderColorIcon
                                onClick={onSelectCommissionTerms(item)}
                                color='primary'
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8}>
                  <div className='flex w-full flex-col items-center py-14'>
                    <label className='font-medium'>No data found</label>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!selectedItem && (
        <UpdateCommissionTermsDialog
          open={!!selectedItem}
          onClose={onCloseUpdateDialog}
          onUpdate={onUpdateCommissionTerms}
          commissionTerms={selectedItem}
        />
      )}

      <AppDialog
        open={mode !== undefined}
        onClose={onCloseDialog}
        title={`${mode === 'sign' ? 'Sign' : 'Activate'} Commission Terms`}
        ButtonNegative={
          <AppButton onClick={onCloseDialog} color='error'>
            Cancel
          </AppButton>
        }
        ButtonPositive={
          <AppButton onClick={mode === 'sign' ? onSignCommissionTerms : onActivateCommissionTerms}>
            {mode === 'sign' ? 'Sign' : 'Activate'}
          </AppButton>
        }
      >
        <div>
          Are you sure you want to {mode === 'sign' ? 'sign' : 'activate'} this Commission Terms
        </div>
      </AppDialog>
    </div>
  )
}

export default React.memo(TableCommissionTerms)
