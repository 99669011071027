import logoLinkedin from '@/assets/images/logo_linkedin.png'
import InputText, { InputTextRef } from '@/components/InputText'
import { COOKIES, REGEX_EMAIL } from '@/constants'
import useAccount from '@/hooks/useAccount'
import Cookies from 'js-cookie'
import React, { useRef } from 'react'

const Login: React.FC = () => {
  const { login } = useAccount()

  const refEmail = useRef<InputTextRef | null>(null)
  const refPassword = useRef<InputTextRef | null>(null)

  const signIn = async () => {
    const email = refEmail.current?.getValue() || ''
    const password = refPassword.current?.getValue() || ''
    if (!email || (email && !email.trim()) || !email.match(REGEX_EMAIL)) {
      refEmail.current?.setError('Please enter valid email')
    }
    if (!password || (password && !password.trim())) {
      refPassword.current?.setError('Please enter password')
    }
    if (email && password && email.match(REGEX_EMAIL)) {
      login({
        variables: { email, password },
        onSuccess: data => {
          const token = data?.login.token
          if (token) {
            Cookies.set(COOKIES.AUTHEN_TOKEN, token, {
              expires: 3,
            })
            window.location.replace(window.location.origin)
          }
        },
      })
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      signIn()
    }
  }

  return (
    <div className='h-screen'>
      <div className='background-auth flex items-center justify-center'>
        <div className='p-8 bg-white rounded-2xl text-center w-96'>
          <div className='mb-2 text-2xl font-bold'>Login</div>
          <div className='mb-6 text-base'>Welcome to NPT Platform</div>

          <InputText
            label='Email'
            placeholder='email@host.com'
            onKeyDown={handleKeyDown}
            ref={refEmail}
          />
          <InputText label='Password' type='password' onKeyDown={handleKeyDown} ref={refPassword} />

          <div className='cursor-pointer text-left text-primary'>Forgot your password?</div>

          <button onClick={signIn} className='mt-6 w-full'>
            Sign in
          </button>

          <div className='my-6 flex flex-row items-center justify-center gap-2'>
            <div className='w-4 h-px bg-black' />
            <div className='text-xs'>Or Sign in with</div>
            <div className='w-4 h-px bg-black' />
          </div>

          <div className='px-5 py-2 border-2 border-black rounded-lg flex flex-row justify-center gap-2 cursor-pointer'>
            <img src={logoLinkedin} width={20} height={20} alt='' />
            <div className='font-medium'>Linkedin</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
