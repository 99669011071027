import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import AppSelect, { AppSelectRef } from '@/components/AppSelect'
import { CommissionTerms, CommissionTermsFrequencyTypeEnum } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onUpdate: (frequencyType: CommissionTermsFrequencyTypeEnum, commission: number) => void
  commissionTerms: CommissionTerms
}

const UpdateCommissionTermsDialog: React.FC<Props> = ({
  open,
  onClose,
  onUpdate,
  commissionTerms,
}) => {
  const refFrequencyType = useRef<AppSelectRef | null>(null)
  const refCommission = useRef<AppInputRef | null>(null)

  const handleUpdateCommissionTerms = () => {
    const frequencyType = refFrequencyType.current?.getValue() as CommissionTermsFrequencyTypeEnum
    const commission = refCommission.current?.getValue()
      ? Number(refCommission.current?.getValue())
      : 0

    if (!frequencyType || !commission) {
      !frequencyType && refFrequencyType.current?.setError('Please select Frequency Type')
      !commission && refCommission.current?.setError('Please enter Commission')
    } else {
      onUpdate(frequencyType, commission)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Update Commission Terms'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleUpdateCommissionTerms}>Update</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppSelect
          list={[
            { value: CommissionTermsFrequencyTypeEnum.ONE_TIME, label: 'One time' },
            { value: CommissionTermsFrequencyTypeEnum.PER_CONTRACT, label: 'Per contract' },
            { value: CommissionTermsFrequencyTypeEnum.PER_TRANSACTION, label: 'Per transaction' },
            { value: CommissionTermsFrequencyTypeEnum.FIVE_CONTRACTS, label: 'Five contracts' },
          ]}
          label='Frequency Type'
          defaultValue={commissionTerms.frequencyType}
          required
          ref={refFrequencyType}
        />

        <AppInput
          label='Commission'
          type='number'
          defaultValue={commissionTerms.commission ? commissionTerms.commission.toString() : ''}
          required
          ref={refCommission}
        />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(UpdateCommissionTermsDialog)
