import AppButton from '@/components/AppButton'
import AppDatePicker, { AppDatePickerRef } from '@/components/AppDatePicker'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import { Contract } from '@/types'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onUpdate: (startDate: string, units?: number, endDate?: string) => void
  contract: Contract
}

const UpdateContractDialog: React.FC<Props> = ({ open, onClose, onUpdate, contract }) => {
  const refUnits = useRef<AppInputRef | null>(null)
  const refStartDate = useRef<AppDatePickerRef | null>(null)
  const refEndDate = useRef<AppDatePickerRef | null>(null)

  const handleUpdateContract = () => {
    const units = refUnits.current?.getValue() ? Number(refUnits.current?.getValue()) : undefined
    const startDate = refStartDate.current?.getValue() || ''
    const endDate = refStartDate.current?.getValue()

    if (!startDate) {
      refStartDate.current?.setError('Please select Start Date')
    } else {
      onUpdate(startDate, units, endDate)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Update Contract'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleUpdateContract}>Update</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppInput
          label='Units'
          type='number'
          defaultValue={contract.units ? contract.units.toString() : ''}
          ref={refUnits}
        />

        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <AppDatePicker
            label='Start Date'
            required
            defaultValue={moment(contract.startDate)}
            ref={refStartDate}
          />
          <AppDatePicker
            label='End Date'
            defaultValue={contract.endDate ? moment(contract.endDate) : null}
            ref={refEndDate}
          />
        </Stack>
      </Stack>
    </AppDialog>
  )
}

export default React.memo(UpdateContractDialog)
