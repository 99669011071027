export enum COOKIES {
  AUTHEN_TOKEN = 'AUTHEN_TOKEN',
}

export const REGEX_EMAIL =
  /^(?!.*\+\D)[^?<>()[\],;!#$%&*~`'?/=|:\s@^"]+(\.[^<>()[\]\\.,;!#$%&*~`'?/=|:\s@^"]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

export const REGEX_INPUTS = {
  ALPHABET_AND_SPACE: /^[a-zA-Z\s]+$/,
  INCLUDE_SPECIAL_CHARACTER: /[^a-zA-Z0-9]/,
  NOT_ONLY_INCLUDE_ALPHABET: /[^a-zA-Z]/,
  NOT_ONLY_INCLUDE_NUMBER: /[^0-9]/,
}

export const FORMAT_TIME = {
  YEAR_MONTH_DAY: 'YYYY/MM/DD',
  MONTH_DAY: 'MM/DD',
  DATE_HOUR_MINUTE: 'dddd HH:mm',
  HOUR_MINUTE: 'HH:mm',
  HOUR_MINUTE_SECOND: 'HH:mm:ss',
  YEAR_MONTH_DAY_TIME: 'YYYY/MM/DD HH:mm',
  MONTH_DAY_TIME: 'MM/DD HH:mm',
  YEAR_WEEK: 'YYYY-[W]WW',
}

export const DEFAULT_PAGESIZE = 10
