import { RootState } from '@/redux/store'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
}

const TopBar: React.FC<Props> = ({ title }) => {
  const { profile } = useSelector((state: RootState) => state.common)
  const navigate = useNavigate()

  const goToAccountPage = () => {
    navigate('/account')
  }

  return (
    <div className='sticky left-0 top-0 z-[98] h-16 w-full flex flex-row justify-center bg-white shadow-sm md:pl-64'>
      <div className='flex flex-row items-center justify-between w-full px-6'>
        <div className='flex flex-row items-center'>
          <div className='block md:hidden mr-2 cursor-pointer'>
            <MenuIcon fontSize='large' />
          </div>
          <div className='text-3xl font-semibold'>{title}</div>
        </div>

        {(profile.name || profile.email) && (
          <div
            onClick={goToAccountPage}
            className='text-primary text-lg font-medium cursor-pointer'
          >
            Welcome, {profile.name || profile.email}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(TopBar)
