import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import { GET_EXPERTS } from '@/graphql/queries'
import { GetExpertsResponse } from '@/graphql/types'

const useExperts = () => {
  const [getListExperts] = useCustomLazyQuery<GetExpertsResponse>({ query: GET_EXPERTS })

  return { getListExperts }
}

export default useExperts
