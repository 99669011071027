import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import { FORMAT_TIME } from '@/constants'
import { Contract } from '@/types'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import moment from 'moment'
import React from 'react'
import Status from './Status'

type Props = {
  open: boolean
  onClose: () => void
  contract: Contract
}

const DetailContractDialog: React.FC<Props> = ({ open, onClose, contract }) => {
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Detail Contract'
      size='md'
      ButtonPositive={<AppButton onClick={onClose}>Close</AppButton>}
    >
      <div>
        <div className='w-full flex flex-row items-center gap-6 pb-6 border-b'>
          <div className='flex-none w-32 h-32 rounded-full bg-fuchsia-200' />
          <div className='w-full'>
            <div className='text-xl font-medium'>{contract.expertUser.name}</div>
            <div className='mt-2'>{contract.expertUser.email}</div>
            <div className='mt-2 flex flex-row items-center justify-between'>
              <div>{contract.expertOrganisation?.name}</div>
              <div className='text-subtitle'>{`${contract.expertOrganisation.type} | ${contract.expertUser.role}`}</div>
            </div>
          </div>
        </div>

        <div className='mt-6 w-full grid grid-cols-2 gap-2 font-medium'>
          <div>Sponsor:</div>
          <div>{contract.sponsorOrganisation.name}</div>
          <div>Expert:</div>
          <div>{contract.expertOrganisation.name}</div>
          <div>Status:</div>
          <div>
            <Status status={contract.status} />
          </div>
          <div>Billing Frequency:</div>
          <div>{contract.billingTimeUnit}</div>
          <div>Units:</div>
          <div>{contract.units}</div>
          <div>Payment Terms:</div>
          <div>{contract.paymentTerms}</div>
          <div>Start Date:</div>
          <div>{moment(contract.startDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}</div>
          <div>End Date:</div>
          <div>
            {contract.endDate && moment(contract.endDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}
          </div>
          <div>Sponsor Signed:</div>
          <div>
            {contract.sponsorSigned ? (
              <CheckCircleIcon color='info' />
            ) : (
              <CancelIcon color='error' />
            )}
          </div>
          <div>Expert Signed:</div>
          <div>
            {contract.expertSigned ? (
              <CheckCircleIcon color='info' />
            ) : (
              <CancelIcon color='error' />
            )}
          </div>
          <div>SOP Compensation:</div>
          <div>
            {contract.sopCompensation
              ? `${contract.sopCompensation.type} - ${contract.sopCompensation.unitRate}`
              : ''}
          </div>
          <div>Cash Compensation:</div>
          <div>{contract.cashCompensation?.unitRate}</div>
          <div>Created At:</div>
          <div>{moment(contract.createdAt).format(FORMAT_TIME.YEAR_MONTH_DAY_TIME)}</div>
          <div>Updated At:</div>
          <div>
            {contract.updatedAt &&
              moment(contract.updatedAt).format(FORMAT_TIME.YEAR_MONTH_DAY_TIME)}
          </div>
        </div>
      </div>
    </AppDialog>
  )
}

export default React.memo(DetailContractDialog)
