import { OrganisationTypeEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  type: OrganisationTypeEnum
}

const OrganisationTypeChip: React.FC<Props> = ({ type }) => {
  switch (type) {
    case OrganisationTypeEnum.ADMIN:
      return (
        <Chip
          label={OrganisationTypeEnum.ADMIN}
          size='small'
          color='secondary'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case OrganisationTypeEnum.PLATFORM:
      return (
        <Chip
          label={OrganisationTypeEnum.PLATFORM}
          size='small'
          color='primary'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case OrganisationTypeEnum.EXPERT:
      return (
        <Chip
          label={OrganisationTypeEnum.EXPERT}
          size='small'
          color='success'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case OrganisationTypeEnum.SPONSOR:
      return (
        <Chip
          label={OrganisationTypeEnum.SPONSOR}
          size='small'
          color='info'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(OrganisationTypeChip)
