import { hideLoading, showAlert, showLoading } from '@/redux/reducers/common.slice'
import {
  ApolloError,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { DocumentNode } from 'graphql'
import { useDispatch } from 'react-redux'

interface HandleMutationParams<TData, TVariables extends OperationVariables>
  extends MutationHookOptions<TData, TVariables> {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  variables?: TVariables
}

interface MutationOptions<TData, TVariables> {
  variables?: TVariables
  onSuccess?: (data: TData) => void
  onError?: (error: ApolloError) => void
}

const useCustomMutation = <TData, TVariables extends OperationVariables = {}>({
  mutation,
  variables,
  ...options
}: HandleMutationParams<TData, TVariables>): [
  (opts?: MutationOptions<TData, TVariables>) => void,
  MutationResult<TData>,
] => {
  const dispatch = useDispatch()

  const [executeMutation, result] = useMutation<TData, TVariables>(mutation, {
    variables,
    ...options,
  })

  const performMutation = async (opts?: MutationOptions<TData, TVariables>) => {
    dispatch(showLoading())
    try {
      const response = await executeMutation({ variables: opts?.variables })
      if (response.errors) {
        const message = response.errors[0].message
        dispatch(showAlert({ message }))
        if (opts?.onError) {
          opts.onError(new ApolloError({ graphQLErrors: response.errors }))
        }
      } else if (response.data && opts?.onSuccess) {
        opts.onSuccess(response.data)
      }
    } catch (error) {
      if (opts?.onError) {
        opts.onError(error as ApolloError)
      }
    } finally {
      dispatch(hideLoading())
    }
  }

  return [performMutation, result] as const
}

export default useCustomMutation
