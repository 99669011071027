import AppButton from '@/components/AppButton'
import AppDatePicker, { AppDatePickerRef } from '@/components/AppDatePicker'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import { AddOccupationParams } from '@/graphql/types'
import { Occupation } from '@/types'
import Stack from '@mui/material/Stack'
import moment from 'moment'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  mode: 'create' | 'update'
  onClose: () => void
  onCreate: (params: AddOccupationParams) => void
  onUpdate: (
    title?: string,
    position?: string,
    fromDate?: string,
    toDate?: string,
    summary?: string,
    company?: string,
    country?: string,
    image?: string,
  ) => void
  occupation?: Occupation
}

const OccupationDialog: React.FC<Props> = ({
  open,
  onClose,
  mode,
  onCreate,
  onUpdate,
  occupation,
}) => {
  const refTitle = useRef<AppInputRef | null>(null)
  const refPosition = useRef<AppInputRef | null>(null)
  const refFromDate = useRef<AppDatePickerRef | null>(null)
  const refToDate = useRef<AppDatePickerRef | null>(null)
  const refCompany = useRef<AppInputRef | null>(null)
  const refCountry = useRef<AppInputRef | null>(null)
  const refSummary = useRef<AppInputRef | null>(null)

  const onApply = () => {
    const title = refTitle.current?.getValue() || ''
    const position = refPosition.current?.getValue() || ''
    const fromDate = refFromDate.current?.getValue() || ''
    const toDate = refToDate.current?.getValue() || ''
    const company = refCompany.current?.getValue() || ''
    const country = refCountry.current?.getValue() || ''
    const summary = refSummary.current?.getValue() || ''

    if (!title || !position || !fromDate || !toDate || !company || !country || !summary) {
      !title && refTitle.current?.setError('Please enter Title')
      !position && refPosition.current?.setError('Please enter Position')
      !fromDate && refFromDate.current?.setError('Please select From Date')
      !toDate && refToDate.current?.setError('Please select To Date')
      !company && refCompany.current?.setError('Please enter Company')
      !country && refCountry.current?.setError('Please enter Country')
      !summary && refSummary.current?.setError('Please enter Summary')
    } else {
      if (mode === 'create') {
        const params: AddOccupationParams = {
          title,
          position,
          fromDate,
          toDate,
          summary,
          company,
          country,
        }
        onCreate(params)
      } else {
        onUpdate(title, position, fromDate, toDate, summary, company, country)
      }
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={`${mode === 'create' ? 'Create' : 'Update'} Occupation`}
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={
        <AppButton onClick={onApply}>{mode === 'create' ? 'Create' : 'Update'}</AppButton>
      }
    >
      <Stack spacing={2} my={1}>
        <AppInput label='Title' defaultValue={occupation?.title} required ref={refTitle} />

        <AppInput label='Position' defaultValue={occupation?.position} required ref={refPosition} />

        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <AppDatePicker
            label='From Date'
            defaultValue={occupation?.fromDate ? moment(occupation.fromDate) : null}
            required
            ref={refFromDate}
          />
          <AppDatePicker
            label='To Date'
            defaultValue={occupation?.toDate ? moment(occupation.toDate) : null}
            required
            ref={refToDate}
          />
        </Stack>

        <AppInput label='Company' defaultValue={occupation?.company} required ref={refCompany} />

        <AppInput label='Country' defaultValue={occupation?.country} required ref={refCountry} />

        <AppInput
          label='Summary'
          defaultValue={occupation?.summary}
          multiline
          required
          ref={refSummary}
        />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(OccupationDialog)
