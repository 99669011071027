import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AppAlert from './components/AppAlert'
import AppLoading from './components/AppLoading'
import { COOKIES } from './constants'
import useAccount from './hooks/useAccount'
import useOrganisationType from './hooks/useOrganisationType'
import Account from './pages/Account'
import ConfirmInvite from './pages/Auth/ConfirmInvite'
import Login from './pages/Auth/Login'
import CommissionTerms from './pages/CommissionTerms'
import Connections from './pages/Connections'
import Contracts from './pages/Contracts'
import Conversations from './pages/Conversations'
import Dashboard from './pages/Dashboard'
import Experts from './pages/Experts'
import PendingTasks from './pages/PendingTasks'
import Timesheets from './pages/Timesheets'
import { setProfile } from './redux/reducers/common.slice'

function App() {
  const navigate = useNavigate()
  const token = Cookies.get(COOKIES.AUTHEN_TOKEN)
  const dispatch = useDispatch()
  const { getProfile } = useAccount()
  const { isPlatform, isExpert, isSponsor } = useOrganisationType()

  useEffect(() => {
    if (!token && window.location.pathname !== '/login') {
      navigate('/login', { replace: true })
    } else {
      getProfile({
        onSuccess: data => {
          dispatch(setProfile(data.profile))
        },
      })
    }
  }, [])

  return (
    <>
      <Routes>
        <Route path='/' index element={<Dashboard />} />
        <Route path='/account' element={<Account />} />
        <Route path='/login' element={<Login />} />
        <Route path='/confirm-invite/:token' element={<ConfirmInvite />} />
        <Route path='/contracts' element={<Contracts />} />
        <Route path='/commission-terms' element={<CommissionTerms />} />
        <Route path='/timesheets' element={<Timesheets />} />
        {isPlatform && (
          <>
            <Route path='/pending-tasks' element={<PendingTasks />} />
            <Route path='/connections' element={<Connections />} />
          </>
        )}
        {isExpert && (
          <>
            <Route path='/conversations' element={<Conversations />} />
          </>
        )}
        {isSponsor && (
          <>
            <Route path='/conversations' element={<Conversations />} />
            <Route path='/experts' element={<Experts />} />
          </>
        )}
      </Routes>

      <AppLoading />
      <AppAlert />
    </>
  )
}

export default App
