import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import { RootState } from '@/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'
import UserInfo from './components/UserInfo'
import UserTabs from './components/UserTabs'

const Account: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.common)

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Account' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white flex flex-row gap-6'>
          <UserInfo profile={profile} />

          <UserTabs profile={profile} />
        </div>
      </div>
    </div>
  )
}

export default Account
