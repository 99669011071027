import AppButton from '@/components/AppButton'
// import AppPagination from '@/components/AppPagination'
import AppDialog from '@/components/AppDialog'
import SideBar from '@/components/SideBar'
import Tooltip from '@/components/Tooltip'
import TopBar from '@/components/TopBar'
import { FORMAT_TIME } from '@/constants'
import { CreateContractParams, UpdateContractParams } from '@/graphql/types'
import useContracts from '@/hooks/useContracts'
import useOrganisationType from '@/hooks/useOrganisationType'
import { showAlert } from '@/redux/reducers/common.slice'
import { Contract, ContractStatusEnum, SopCompensationTypeEnum } from '@/types'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DrawIcon from '@mui/icons-material/Draw'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateContractDialog from './components/CreateContractDialog'
import DetailContractDialog from './components/DetailContractDialog'
import PaymentTerms from './components/PaymentTerms'
import SetCashCompensationDialog from './components/SetCashCompensationDialog'
import SetSopCompensationDialog from './components/SetSopCompensationDialog'
import Status from './components/Status'
import UpdateContractDialog from './components/UpdateContractDialog'

const Contracts: React.FC = () => {
  const {
    getListContracts,
    refetch,
    createContract,
    updateContract,
    signContract,
    setSopCompensation,
    setCashCompensation,
  } = useContracts()
  const dispatch = useDispatch()
  const { isPlatform, isExpert, isSponsor } = useOrganisationType()

  const [data, setData] = useState<Contract[]>([])
  // const [page, setPage] = useState(1)
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Contract | undefined>(undefined)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openSignDialog, setOpenSignDialog] = useState(false)
  const [openSetSopDialog, setOpenSetSopDialog] = useState(false)
  const [openSetCashDialog, setOpenSetCashDialog] = useState(false)
  const [openDetailDialog, setOpenDetailDialog] = useState(false)

  useEffect(() => {
    getListContracts({
      onSuccess: response => {
        setData(response.contracts)
      },
    })
  }, [])

  // const onChangePage = (value: number) => {
  //   setPage(value)
  // }

  const onCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const onCloseUpdateDialog = () => {
    setSelectedItem(undefined)
    setOpenUpdateDialog(false)
  }

  const onCloseSignDialog = () => {
    setSelectedItem(undefined)
    setOpenSignDialog(false)
  }

  const onCloseSetSopDialog = () => {
    setSelectedItem(undefined)
    setOpenSetSopDialog(false)
  }

  const onCloseSetCashDialog = () => {
    setSelectedItem(undefined)
    setOpenSetCashDialog(false)
  }

  const onCloseDetailDialog = () => {
    setSelectedItem(undefined)
    setOpenDetailDialog(false)
  }

  const onCreateContract = (params: CreateContractParams) => {
    createContract({
      variables: params,
      onSuccess: () => {
        onCloseCreateDialog()
        dispatch(showAlert({ message: 'Create Contract successfully', type: 'success' }))
        refetch().then(response => {
          setData(response.data.contracts)
        })
      },
    })
  }

  const onUpdateContract = (startDate: string, units?: number, endDate?: string) => {
    if (selectedItem?.id) {
      const params: UpdateContractParams = {
        details: {
          contractId: selectedItem.id,
          units,
          startDate,
          endDate,
        },
      }
      updateContract({
        variables: params,
        onSuccess: () => {
          onCloseUpdateDialog()
          dispatch(showAlert({ message: 'Update Contract successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.contracts)
          })
        },
      })
    }
  }

  const onSignContract = () => {
    if (selectedItem?.id) {
      signContract({
        variables: { contractId: selectedItem.id },
        onSuccess: () => {
          onCloseSignDialog()
          dispatch(showAlert({ message: 'Sign Contract successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.contracts)
          })
        },
      })
    }
  }

  const onSetSopCompensation = (
    unitRate: number,
    type: SopCompensationTypeEnum,
    description?: string,
  ) => {
    if (selectedItem?.id) {
      setSopCompensation({
        variables: { contractId: selectedItem.id, unitRate, type, description },
        onSuccess: () => {
          onCloseSetSopDialog()
          dispatch(showAlert({ message: 'Set SOP Compensation successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.contracts)
          })
        },
      })
    }
  }

  const onSetCashCompensation = (unitRate: number, description?: string) => {
    if (selectedItem?.id) {
      setCashCompensation({
        variables: { contractId: selectedItem.id, unitRate, description },
        onSuccess: () => {
          onCloseSetCashDialog()
          dispatch(showAlert({ message: 'Set Cash Compensation successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.contracts)
          })
        },
      })
    }
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Contracts' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <Stack direction='row' justifyContent='space-between' mb={3}>
            <div className='text-2xl font-semibold'>List Contracts</div>

            {isSponsor && (
              <AppButton onClick={() => setOpenCreateDialog(true)}>Create Contract</AppButton>
            )}
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow sx={{ background: '#F7F7F7' }}>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
                  {(isPlatform || isExpert) && (
                    <TableCell sx={{ fontWeight: 600, py: 1 }}>Sponsor Org</TableCell>
                  )}
                  {(isPlatform || isSponsor) && (
                    <TableCell sx={{ fontWeight: 600, py: 1 }}>Expert Org</TableCell>
                  )}
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Billing Frequency</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Units
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    Payment Terms
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Start Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    End Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    SOP Compensation
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    Cash Compensation
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => (
                    <TableRow key={item.id}>
                      {/* <TableCell>{(page - 1) * DEFAULT_PAGESIZE + index + 1}</TableCell> */}
                      <TableCell>{index + 1}</TableCell>
                      {!isSponsor && <TableCell>{item.sponsorOrganisation?.name}</TableCell>}
                      {!isExpert && <TableCell>{item.expertOrganisation?.name}</TableCell>}
                      <TableCell>{item.billingTimeUnit}</TableCell>
                      <TableCell align='right'>{item.units}</TableCell>
                      <TableCell align='center'>
                        <PaymentTerms paymentTerms={item.paymentTerms} />
                      </TableCell>
                      <TableCell align='right'>
                        {moment(item.startDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='right'>
                        {item.endDate && moment(item.endDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='center'>
                        {item.sopCompensation
                          ? `${item.sopCompensation?.type} - ${item.sopCompensation?.unitRate}`
                          : ''}
                      </TableCell>
                      <TableCell align='center'>{item.cashCompensation?.unitRate}</TableCell>
                      <TableCell align='center'>
                        <Status status={item.status} />
                      </TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' justifyContent='end' spacing={1}>
                          {((isSponsor &&
                            (item.status === ContractStatusEnum.CREATED ||
                              item.status === ContractStatusEnum.PENDING_SIGNATURES) &&
                            (item.sopCompensation || item.cashCompensation)) ||
                            (isExpert && item.status === ContractStatusEnum.SPONSOR_SIGNED)) && (
                            <Tooltip title='Sign'>
                              <DrawIcon
                                onClick={() => {
                                  setSelectedItem(item)
                                  setOpenSignDialog(true)
                                }}
                                color='primary'
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}

                          {isSponsor &&
                            (item.status === ContractStatusEnum.CREATED ||
                              item.status === ContractStatusEnum.PENDING_SIGNATURES) && (
                              <Tooltip title='Set SOP compensation'>
                                <RequestQuoteIcon
                                  onClick={() => {
                                    setSelectedItem(item)
                                    setOpenSetSopDialog(true)
                                  }}
                                  color='primary'
                                  sx={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            )}
                          {isSponsor &&
                            (item.status === ContractStatusEnum.CREATED ||
                              item.status === ContractStatusEnum.PENDING_SIGNATURES) && (
                              <Tooltip title='Set cash compensation'>
                                <AttachMoneyIcon
                                  onClick={() => {
                                    setSelectedItem(item)
                                    setOpenSetCashDialog(true)
                                  }}
                                  color='primary'
                                  sx={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            )}

                          {isSponsor &&
                            (item.status === ContractStatusEnum.CREATED ||
                              item.status === ContractStatusEnum.PENDING_SIGNATURES) && (
                              <Tooltip title='Update'>
                                <BorderColorIcon
                                  onClick={() => {
                                    setSelectedItem(item)
                                    setOpenUpdateDialog(true)
                                  }}
                                  color='primary'
                                  sx={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            )}

                          <Tooltip title='View'>
                            <VisibilityOutlinedIcon
                              onClick={() => {
                                setSelectedItem(item)
                                setOpenDetailDialog(true)
                              }}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <div className='flex w-full flex-col items-center py-14'>
                        <label className='font-medium'>No data found</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* {!!data?.contracts.length && (
            <AppPagination totalPage={50} currentPage={page} onChangePage={onChangePage} />
          )} */}
        </div>

        {!!selectedItem && (
          <DetailContractDialog
            open={openDetailDialog}
            onClose={onCloseDetailDialog}
            contract={selectedItem}
          />
        )}

        <CreateContractDialog
          open={openCreateDialog}
          onClose={onCloseCreateDialog}
          onCreate={onCreateContract}
        />

        {!!selectedItem && (
          <UpdateContractDialog
            open={openUpdateDialog}
            onClose={onCloseUpdateDialog}
            onUpdate={onUpdateContract}
            contract={selectedItem}
          />
        )}

        <AppDialog
          open={openSignDialog}
          onClose={onCloseSignDialog}
          title='Sign Contract'
          ButtonNegative={
            <AppButton onClick={onCloseSignDialog} color='error'>
              Cancel
            </AppButton>
          }
          ButtonPositive={<AppButton onClick={onSignContract}>Sign</AppButton>}
        >
          <div>Are you sure you want to sign this Contract</div>
        </AppDialog>

        {!!selectedItem && (
          <SetSopCompensationDialog
            open={openSetSopDialog}
            onClose={onCloseSetSopDialog}
            onUpdate={onSetSopCompensation}
            contract={selectedItem}
          />
        )}

        {!!selectedItem && (
          <SetCashCompensationDialog
            open={openSetCashDialog}
            onClose={onCloseSetCashDialog}
            onUpdate={onSetCashCompensation}
            contract={selectedItem}
          />
        )}
      </div>
    </div>
  )
}

export default Contracts
