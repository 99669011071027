import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

export const CONFIRM_INVITE = gql`
  mutation ConfirmInvite($token: String!, $password: String!, $name: String!) {
    confirmInvite(token: $token, password: $password, name: $name) {
      status
      message
    }
  }
`

export const VERIFY_REGISTRATION_CODE = gql`
  mutation VerifyRegistrationCode($email: String!, $code: String!) {
    verifyRegistrationCode(email: $email, code: $code) {
      id
    }
  }
`

export const INVITE_EXPERT = gql`
  mutation InviteExpert($organisationName: String!, $email: String!, $type: Float) {
    inviteExpert(organisationName: $organisationName, email: $email, type: $type) {
      status
      message
    }
  }
`

export const INVITE_SPONSOR = gql`
  mutation InviteSponsor($organisationName: String!, $email: String!, $type: Float) {
    inviteSponsor(organisationName: $organisationName, email: $email, type: $type) {
      status
      message
    }
  }
`

export const SIGN_COMMISSION_TERMS = gql`
  mutation SignCommissionTerms($commissionTermsId: Float!) {
    signCommissionTerms(commissionTermsId: $commissionTermsId) {
      id
      status
    }
  }
`

export const ACTIVATE_COMMISSION_TERMS = gql`
  mutation ActivateCommissionTerms($commissionTermsId: Float!) {
    activateCommissionTerms(commissionTermsId: $commissionTermsId) {
      id
      status
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation CreateContract(
    $expertUserId: Float!
    $billingTimeUnit: BillingTimeUnit!
    $units: Float!
    $startDate: DateTime!
    $endDate: DateTime
    $paymentTerms: ContractPaymentTerms!
  ) {
    createContract(
      expertUserId: $expertUserId
      billingTimeUnit: $billingTimeUnit
      units: $units
      startDate: $startDate
      endDate: $endDate
      paymentTerms: $paymentTerms
    ) {
      id
      status
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation UpdateContract($details: UpdateContractInfo!) {
    updateContract(details: $details) {
      id
      status
    }
  }
`

export const CREATE_COMMISSION_TERMS = gql`
  mutation CreateCommissionTerms(
    $connectionId: Float!
    $frequencyType: CommissionTermsFrequencyType!
    $commission: Float!
  ) {
    createCommissionTerms(
      connectionId: $connectionId
      frequencyType: $frequencyType
      commission: $commission
    ) {
      id
      status
    }
  }
`

export const UPDATE_COMMISSION_TERMS = gql`
  mutation UpdateCommissionTerms(
    $termsId: Float!
    $frequencyType: CommissionTermsFrequencyType!
    $commission: Float!
  ) {
    updateCommissionTerms(
      termsId: $termsId
      frequencyType: $frequencyType
      commission: $commission
    ) {
      id
      status
    }
  }
`

export const ADD_OCCUPATION = gql`
  mutation AddOccupation(
    $title: String!
    $position: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $summary: String!
    $company: String!
    $country: String!
  ) {
    addOccupation(
      title: $title
      position: $position
      fromDate: $fromDate
      toDate: $toDate
      summary: $summary
      company: $company
      country: $country
    ) {
      id
    }
  }
`

export const UPDATE_OCCUPATION = gql`
  mutation UpdateOccupation(
    $id: Float!
    $title: String
    $position: String
    $fromDate: DateTime
    $toDate: DateTime
    $summary: String
    $image: String
    $company: String
    $country: String
  ) {
    updateOccupation(
      id: $id
      title: $title
      position: $position
      fromDate: $fromDate
      toDate: $toDate
      summary: $summary
      image: $image
      company: $company
      country: $country
    ) {
      id
    }
  }
`

export const SIGN_CONTRACT = gql`
  mutation SignContract($contractId: Float!) {
    signContract(contractId: $contractId) {
      id
    }
  }
`

export const SET_SOP_COMPENSATION = gql`
  mutation SetSopCompensation(
    $contractId: Float!
    $unitRate: Float!
    $type: SopCompensationType!
    $description: String
  ) {
    setSopCompensation(
      contractId: $contractId
      unitRate: $unitRate
      type: $type
      description: $description
    ) {
      id
      status
    }
  }
`

export const SET_CASH_COMPENSATION = gql`
  mutation SetCashCompensation($contractId: Float!, $unitRate: Float!, $description: String) {
    setCashCompensation(contractId: $contractId, unitRate: $unitRate, description: $description) {
      id
      status
    }
  }
`

export const CREATE_TIMESHEET = gql`
  mutation CreateTimesheet(
    $contractId: Float!
    $deliveryDate: DateTime!
    $units: Float!
    $summary: JSON!
  ) {
    addTimesheet(
      contractId: $contractId
      deliveryDate: $deliveryDate
      units: $units
      summary: $summary
    ) {
      id
    }
  }
`

export const UPDATE_TIMESHEET = gql`
  mutation UpdateTimesheet(
    $timesheetId: Float!
    $deliveryDate: DateTime
    $units: Float
    $summary: JSON!
  ) {
    updateTimesheet(
      timesheetId: $timesheetId
      deliveryDate: $deliveryDate
      units: $units
      summary: $summary
    ) {
      id
    }
  }
`

export const SIGN_TIMESHEET = gql`
  mutation SignTimesheet($timesheetId: Float!) {
    signTimesheet(timesheetId: $timesheetId) {
      id
    }
  }
`
