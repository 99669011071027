import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import useCustomMutation from '@/graphql/hooks/useCustomMutation'
import {
  CREATE_CONTRACT,
  SET_CASH_COMPENSATION,
  SET_SOP_COMPENSATION,
  SIGN_CONTRACT,
  UPDATE_CONTRACT,
} from '@/graphql/mutations'
import { GET_CONTRACTS, GET_DETAIL_CONTRACT } from '@/graphql/queries'
import {
  CreateContractParams,
  CreateContractResponse,
  GetContractsResponse,
  GetDetailContractParams,
  GetDetailContractResponse,
  SetCashCompensationParams,
  SetCashCompensationResponse,
  SetSopCompensationParams,
  SetSopCompensationResponse,
  SignContractParams,
  SignContractResponse,
  UpdateContractParams,
  UpdateContractResponse,
} from '@/graphql/types'

const useContracts = () => {
  const [getListContracts, { refetch }] = useCustomLazyQuery<GetContractsResponse>({
    query: GET_CONTRACTS,
  })
  const [getDetailContract] = useCustomLazyQuery<
    GetDetailContractResponse,
    GetDetailContractParams
  >({
    query: GET_DETAIL_CONTRACT,
  })
  const [createContract] = useCustomMutation<CreateContractResponse, CreateContractParams>({
    mutation: CREATE_CONTRACT,
  })
  const [updateContract] = useCustomMutation<UpdateContractResponse, UpdateContractParams>({
    mutation: UPDATE_CONTRACT,
  })
  const [signContract] = useCustomMutation<SignContractResponse, SignContractParams>({
    mutation: SIGN_CONTRACT,
  })
  const [setSopCompensation] = useCustomMutation<
    SetSopCompensationResponse,
    SetSopCompensationParams
  >({
    mutation: SET_SOP_COMPENSATION,
  })
  const [setCashCompensation] = useCustomMutation<
    SetCashCompensationResponse,
    SetCashCompensationParams
  >({
    mutation: SET_CASH_COMPENSATION,
  })

  return {
    getListContracts,
    refetch,
    getDetailContract,
    createContract,
    updateContract,
    signContract,
    setSopCompensation,
    setCashCompensation,
  }
}

export default useContracts
