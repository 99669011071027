import SideBar from '@/components/SideBar'
import useDashboard from '@/hooks/useDashboard'
import { Dashboard } from '@/types'
import React, { useEffect, useState } from 'react'
import Contracts from '../Contracts'
import PendingTasks from '../PendingTasks'
import Timesheets from '../Timesheets'

const SponsorDashboard: React.FC = () => {
  const { getSponsorDashboard } = useDashboard()

  const [data, setData] = useState<Dashboard>()

  useEffect(() => {
    getSponsorDashboard({
      onSuccess: response => {
        setData(response.dashboard)
      },
    })
  }, [])

  return (
    <div className='w-full'>
      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] p-8'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
            <PendingTasks pendingTasks={data?.pendingTasksArr || []} />

            <Contracts contracts={data?.contractsArr || []} />

            <Timesheets timesheets={data?.timesheetsArr || []} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SponsorDashboard)
