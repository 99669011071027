import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

export type ItemSelect = {
  value: string | number
  label: string | number
}

type Props = {
  list: ItemSelect[]
  label?: string
  size?: 'small' | 'medium'
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  defaultValue?: string
  onChange?: (value: string) => void
}

export type AppSelectRef = {
  clear: () => void
  getValue: () => string | undefined
  setError: (msg: string) => void
}

const AppSelect: React.ForwardRefRenderFunction<AppSelectRef, Props> = (props, ref) => {
  const {
    list,
    label,
    size = 'medium',
    disabled,
    readOnly,
    required,
    variant = 'outlined',
    defaultValue,
    onChange,
  } = props
  const [value, setValue] = useState<string>(defaultValue || '')
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        setValue('')
        setErrorMessage('')
      },
      getValue: () => value || undefined,
      setError: (msg: string) => {
        setErrorMessage(msg)
      },
    }),
    [value],
  )

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.toString())
    setErrorMessage('')
    onChange && onChange(e.target.value.toString())
  }

  return (
    <TextField
      label={label}
      fullWidth
      select
      size={size}
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={disabled}
      required={required}
      variant={variant}
      InputProps={{
        readOnly: readOnly,
      }}
      value={value}
      onChange={onChangeValue}
    >
      {list.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default React.memo(forwardRef(AppSelect))
