import { formatTimestamp } from '@/helpers'
import { RootState } from '@/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'

type Props = {
  chatbox: any
  selectChatbox: (selectedChatbox: any) => void
  selectedChatbox: any
}

const ChatboxItem: React.FC<Props> = ({ chatbox, selectChatbox, selectedChatbox }) => {
  const { profile } = useSelector((state: RootState) => state.common)

  const onSelectChatbox = () => {
    selectChatbox(chatbox)
  }

  return (
    <div
      onClick={onSelectChatbox}
      className={`flex flex-row items-center gap-2 border-b px-2 py-4 cursor-pointer hover:bg-primary-hover-light ${chatbox.id === selectedChatbox?.id && 'bg-[#c1c4ff]'}`}
    >
      <div className='flex-none w-14 h-14 rounded-full border flex items-center justify-center'>
        avatar
      </div>
      <div>
        <div className='flex flex-row items-center'>
          <div className='flex-1 text-base font-medium one-line-display'>
            {chatbox.interlocutor.name}
          </div>
          <div className='text-[10px] text-gray-500'>
            {formatTimestamp(chatbox.last_message.timeStamp)}
          </div>
        </div>
        <div className='one-line-display text-gray-800'>
          {profile.id === chatbox.last_message.sender.id ? 'You: ' : ''}
          {chatbox.last_message.content}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ChatboxItem)
