import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import useCustomMutation from '@/graphql/hooks/useCustomMutation'
import {
  ACTIVATE_COMMISSION_TERMS,
  CREATE_COMMISSION_TERMS,
  SIGN_COMMISSION_TERMS,
  UPDATE_COMMISSION_TERMS,
} from '@/graphql/mutations'
import { GET_COMMISSION_TERMS } from '@/graphql/queries'
import {
  ActionCommissionTermsParams,
  ActionCommissionTermsResponse,
  CreateCommissionTermsParams,
  CreateCommissionTermsResponse,
  GetCommissionTermsResponse,
  UpdateCommissionTermsParams,
  UpdateCommissionTermsResponse,
} from '@/graphql/types'

const useCommissionTerms = () => {
  const [getListCommissionTerms, { refetch }] = useCustomLazyQuery<GetCommissionTermsResponse>({
    query: GET_COMMISSION_TERMS,
  })
  const [createCommissionTerms] = useCustomMutation<
    CreateCommissionTermsResponse,
    CreateCommissionTermsParams
  >({
    mutation: CREATE_COMMISSION_TERMS,
  })
  const [updateCommissionTerms] = useCustomMutation<
    UpdateCommissionTermsResponse,
    UpdateCommissionTermsParams
  >({
    mutation: UPDATE_COMMISSION_TERMS,
  })
  const [signComissionTerms] = useCustomMutation<
    ActionCommissionTermsResponse,
    ActionCommissionTermsParams
  >({
    mutation: SIGN_COMMISSION_TERMS,
  })
  const [activateComissionTerms] = useCustomMutation<
    ActionCommissionTermsResponse,
    ActionCommissionTermsParams
  >({
    mutation: ACTIVATE_COMMISSION_TERMS,
  })

  return {
    getListCommissionTerms,
    refetch,
    createCommissionTerms,
    updateCommissionTerms,
    signComissionTerms,
    activateComissionTerms,
  }
}

export default useCommissionTerms
