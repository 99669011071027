import { COOKIES } from '@/constants'
import { InviteParams } from '@/graphql/types'
import useAccount from '@/hooks/useAccount'
import { showAlert } from '@/redux/reducers/common.slice'
import { Menu } from '@/types'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
// import ForumIcon from '@mui/icons-material/Forum'
import GavelIcon from '@mui/icons-material/Gavel'
import HubIcon from '@mui/icons-material/Hub'
import LogoutIcon from '@mui/icons-material/Logout'
import PaidIcon from '@mui/icons-material/Paid'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
// import ReceiptIcon from '@mui/icons-material/Receipt'
import useOrganisationType from '@/hooks/useOrganisationType'
import TodayIcon from '@mui/icons-material/Today'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Cookies from 'js-cookie'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppButton from '../AppButton'
import AppDialog from '../AppDialog'
import AppInput, { AppInputRef } from '../AppInput'

const platformMenus: Menu[] = [
  {
    id: 1,
    label: 'Pending Tasks',
    path: '/pending-tasks',
    icon: <PendingActionsIcon />,
  },
  {
    id: 2,
    label: 'Connections',
    path: '/connections',
    icon: <HubIcon />,
  },
  {
    id: 3,
    label: 'Commission Terms',
    path: '/commission-terms',
    icon: <PaidIcon />,
  },
  {
    id: 4,
    label: 'Contracts',
    path: '/contracts',
    icon: <GavelIcon />,
  },
  // {
  //   id: 5,
  //   label: 'Transactions',
  //   path: '/transactions',
  //   icon: <ReceiptIcon />,
  // },
  {
    id: 6,
    label: 'Invite Sponsor',
    path: '',
    icon: <PersonAddIcon />,
  },
  {
    id: 7,
    label: 'Invite Expert',
    path: '',
    icon: <PersonAddIcon />,
  },
]

const expertMenus: Menu[] = [
  // {
  //   id: 1,
  //   label: 'Conversations',
  //   path: '/conversations',
  //   icon: <ForumIcon />,
  // },
  {
    id: 2,
    label: 'Commission Terms',
    path: '/commission-terms',
    icon: <PaidIcon />,
  },
  {
    id: 3,
    label: 'Contracts',
    path: '/contracts',
    icon: <GavelIcon />,
  },
  {
    id: 34,
    label: 'Timesheets',
    path: '/timesheets',
    icon: <TodayIcon />,
  },
  // {
  //   id: 5,
  //   label: 'Transactions',
  //   path: '/transactions',
  //   icon: <ReceiptIcon />,
  // },
]

const sponsorMenus: Menu[] = [
  // {
  //   id: 1,
  //   label: 'Conversations',
  //   path: '/conversations',
  //   icon: <ForumIcon />,
  // },
  {
    id: 2,
    label: 'Commission Terms',
    path: '/commission-terms',
    icon: <PaidIcon />,
  },
  {
    id: 3,
    label: 'Contracts',
    path: '/contracts',
    icon: <GavelIcon />,
  },
  {
    id: 4,
    label: 'Timesheets',
    path: '/timesheets',
    icon: <TodayIcon />,
  },
  // {
  //   id: 5,
  //   label: 'Transactions',
  //   path: '/transactions',
  //   icon: <ReceiptIcon />,
  // },
  {
    id: 6,
    label: 'Experts',
    path: '/experts',
    icon: <AccessibilityIcon />,
  },
]

const SideBar: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pathname = window.location.pathname

  const { inviteExpert, inviteSponsor } = useAccount()
  const { isPlatform, isExpert } = useOrganisationType()

  const [isInviting, setIsInviting] = useState<'Expert' | 'Sponsor' | undefined>(undefined)
  const [openDialogLogout, setOpenDialogLogout] = useState(false)

  const refEmail = useRef<AppInputRef | null>(null)
  const refOrganisationName = useRef<AppInputRef | null>(null)

  const handleCloseDialog = () => {
    setIsInviting(undefined)
  }

  const handleCloseDialogLogout = () => {
    setOpenDialogLogout(false)
  }

  const onClickMenu = (path: string, inviting?: 'Expert' | 'Sponsor' | undefined) => () => {
    setIsInviting(inviting)
    if (!inviting) {
      navigate(path)
    }
  }

  const onInvite = () => {
    const params: InviteParams = {
      organisationName: refOrganisationName.current?.getValue() || '',
      email: refEmail.current?.getValue() || '',
    }
    if (isInviting === 'Expert') {
      inviteExpert({
        variables: params,
        onSuccess: () => {
          handleCloseDialog()
          dispatch(showAlert({ message: 'Invite Expert successfully', type: 'success' }))
        },
      })
    } else if (isInviting === 'Sponsor') {
      inviteSponsor({
        variables: params,
        onSuccess: () => {
          handleCloseDialog()
          dispatch(showAlert({ message: 'Invite Sponsor successfully', type: 'success' }))
        },
      })
    }
  }

  const goHome = () => {
    if (pathname !== '/') {
      navigate('/')
    }
  }

  const onLogout = () => {
    Cookies.remove(COOKIES.AUTHEN_TOKEN)
    navigate('/login', { replace: true })
  }

  return (
    <div className='sidebar shadow-sm md:block hidden'>
      <div className='py-2 flex flex-col h-full'>
        <div
          onClick={goHome}
          className='text-3xl font-semibold text-center mt-1.5 mb-6 cursor-pointer'
        >
          NPT Platform
        </div>

        {(isPlatform ? platformMenus : isExpert ? expertMenus : sponsorMenus).map(menu => (
          <div
            key={menu.id}
            onClick={onClickMenu(
              menu.path,
              menu.label === 'Invite Expert'
                ? 'Expert'
                : menu.label === 'Invite Sponsor'
                  ? 'Sponsor'
                  : undefined,
            )}
            className={`mx-2 p-4 flex flex-row items-center gap-2 cursor-pointer text-base rounded-sm hover:bg-primary-hover hover:text-white ${menu.path && pathname.includes(menu.path) && 'bg-primary text-white'}`}
          >
            <div>{menu.icon}</div>
            <div className='font-medium'>{menu.label}</div>
          </div>
        ))}

        <Divider sx={{ my: 2 }} />

        <div
          onClick={() => setOpenDialogLogout(true)}
          className='mx-2 p-4 flex flex-row items-center gap-2 cursor-pointer text-base rounded-sm hover:bg-primary-hover hover:text-white'
        >
          <LogoutIcon />
          <div className='font-medium'>Logout</div>
        </div>
      </div>

      <AppDialog
        open={!!isInviting}
        onClose={handleCloseDialog}
        title={`Invite ${isInviting}`}
        ButtonNegative={
          <AppButton onClick={handleCloseDialog} color='error'>
            Cancel
          </AppButton>
        }
        ButtonPositive={<AppButton onClick={onInvite}>Invite</AppButton>}
      >
        <Stack spacing={2} my={1}>
          <AppInput label='Organisation Name' ref={refOrganisationName} />

          <AppInput label='Email' ref={refEmail} />
        </Stack>
      </AppDialog>

      <AppDialog
        open={openDialogLogout}
        onClose={handleCloseDialogLogout}
        title='Logout'
        ButtonNegative={
          <AppButton onClick={handleCloseDialogLogout} color='error'>
            Cancel
          </AppButton>
        }
        ButtonPositive={<AppButton onClick={onLogout}>Logout</AppButton>}
      >
        <div>Are you sure you want to Logout</div>
      </AppDialog>
    </div>
  )
}

export default React.memo(SideBar)
