import { Alert } from '@/models/common'
import { User } from '@/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  alert: {
    message: '',
    type: 'error',
    actionOnHide: undefined,
  } as Alert,
  profile: {} as User,
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    showLoading: state => {
      state.loading = true
    },
    hideLoading: state => {
      state.loading = false
    },
    showAlert: (state, action: PayloadAction<Alert>) => {
      state.alert.message = action.payload.message
      state.alert.type = action.payload.type || 'error'
      state.alert.actionOnHide = action.payload.actionOnHide
    },
    hideAlert: state => {
      state.alert.message = ''
      state.alert.type = 'error'
      if (state.alert.actionOnHide) {
        state.alert.actionOnHide()
        state.alert.actionOnHide = undefined
      }
    },
    setProfile: (state, action: PayloadAction<User>) => {
      state.profile = action.payload
    },
  },
})

export const { showLoading, hideLoading, showAlert, hideAlert, setProfile } = commonSlice.actions
export default commonSlice.reducer
