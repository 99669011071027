import { ReactNode } from 'react'

export enum UserStatusEnum {
  INVITATION_SENT = 'INVITATION_SENT',
  PENDING_TOKEN_CONFIRMATION = 'PENDING_TOKEN_CONFIRMATION',
  VERIFIED = 'VERIFIED',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  USER = 'USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum CommissionTermsStatusEnum {
  CREATED = 'CREATED',
  PLATFORM_SIGNED = 'PLATFORM_SIGNED',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  EXPIRED = 'EXPIRED',
}

export enum CommissionTermsFrequencyTypeEnum {
  ONE_TIME = 'ONE_TIME',
  PER_CONTRACT = 'PER_CONTRACT',
  PER_TRANSACTION = 'PER_TRANSACTION',
  FIVE_CONTRACTS = 'FIVE_CONTRACTS',
}

export type CommissionTerms = {
  id: number
  connection: Connection
  status: CommissionTermsStatusEnum
  frequencyType: CommissionTermsFrequencyTypeEnum
  commission?: number
  platformSignature?: User
  partnerSignature?: User
  createdAt: string
  updatedAt: string
}

export type Connection = {
  id: number
  organisation: Organisation
  commissionTerms: CommissionTerms[]
  createdAt: string
  updatedAt: string
}

export type Transaction = {
  id: number
  amount: number
  type: string
  status: string
  account: Account
  user: User
}

export type Account = {
  id: number
  blocked: number
  balance: number
  transactions: Transaction[]
}

export enum TimesheetStatusEnum {
  CREATED = 'CREATED',
  SPONSOR_SIGNED = 'SPONSOR_SIGNED',
  INVOICED = 'INVOICED',
  FULLFILLED = 'FULLFILLED',
  CANCELED = 'CANCELED',
}

export type Timesheet = {
  id: number
  deliveryDate: string
  units: number
  summary: {
    tasks: string[]
  }
  contract: Contract
  expertUser: User
  sponsorSignatory?: User
  status: TimesheetStatusEnum
  createdAt: string
  updatedAt: string
}

export enum BillingTimeUnitEnum {
  HOUR = 'HOUR',
  HALF_DAY = 'HALF_DAY',
  DAY = 'DAY',
}

export enum ContractPaymentTermsEnum {
  PAY_PER_TIMESHEET = 'PAY_PER_TIMESHEET',
  MONTHLY = 'MONTHLY',
}

export type CashCompensation = {
  id: number
  unitRate: number
  description?: string
  contract: Contract
}

export enum SopCompensationTypeEnum {
  VSOP = 'VSOP',
  ESOP = 'ESOP',
}

export type SopCompensation = {
  id: number
  unitRate: number
  description?: string
  contract: Contract
  type: SopCompensationTypeEnum
}

export enum ContractStatusEnum {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  PENDING_SIGNATURES = 'PENDING_SIGNATURES',
  SPONSOR_SIGNED = 'SPONSOR_SIGNED',
  EXPERT_SIGNED = 'EXPERT_SIGNED',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  FULLFILLED = 'FULLFILLED',
  PARTIALLY_FULLFILLED = 'PARTIALLY_FULLFILLED',
  CLOSED = 'CLOSED',
}

export type Contract = {
  id: number
  createdAt: string
  updatedAt: string
  status: ContractStatusEnum
  billingTimeUnit: BillingTimeUnitEnum
  units: number
  sponsorSigned: boolean
  expertSigned: boolean
  paymentTerms: ContractPaymentTermsEnum
  startDate: string
  endDate?: string
  sponsorOrganisation: Organisation
  expertOrganisation: Organisation
  expertUser: User
  platformOrganisation: Organisation
  timesheets: Timesheet[]
  sponsorCommissionTerms?: CommissionTerms
  expertCommissionTerms?: CommissionTerms
  timesheet: Timesheet
  cashCompensation: CashCompensation
  sopCompensation: SopCompensation
}

export type Conversation = {
  id: number
  sponsor: Organisation
  expert: Organisation
  status: number
  title?: string
  message?: string
  sender: User
  readBy?: User
}

export enum OrganisationTypeEnum {
  PLATFORM = 'PLATFORM',
  SPONSOR = 'SPONSOR',
  EXPERT = 'EXPERT',
  ADMIN = 'ADMIN',
}

export type Organisation = {
  id: number
  name: string
  type: OrganisationTypeEnum
  users: User[]
  connections: Connection[]
  account: Account
  contracts: Contract[]
  conversations: Conversation[]
  sponsors: Organisation[]
  experts: Organisation[]
  createdAt: string
}

export type User = {
  id: number
  email: string
  name?: string
  invitationToken?: string
  invitationTokenExpires?: string
  status: UserStatusEnum
  role: UserRoleEnum
  createdAt: string
  organisation: Organisation
  bio: Bio
}

export type SyncLinkedInBioDto = {
  referenceId: string
  status: string
  message: string
}

export type Occupation = {
  id: number
  title: string
  position: string
  company: string
  country: string
  fromDate: string
  toDate: string
  summary: string
  image: string
}

export type Bio = {
  id: number
  occupations: Occupation[]
  summary?: string
}

export type TokenResponse = {
  status: string
  message?: string
  token?: string
}

export type StatusResponse = {
  status: string
  message?: string
}

export enum PendingTaskOwnerEnum {
  OUR_ORGANISATION = 'OUR_ORGANISATION',
  PARTNER_ORGANISATION = 'PARTNER_ORGANISATION',
}

export enum PendingTaskDomainEnum {
  USER_INVITATION = 'USER_INVITATION',
  ACTIVE_COMMISSION_TERMS = 'ACTIVE_COMMISSION_TERMS',
  CONTRACT_SIGNING = 'CONTRACT_SIGNING',
  TIMESHEET_SIGNING = 'TIMESHEET_SIGNING',
}

export type PendingTask = {
  owner: PendingTaskOwnerEnum
  check: PendingTaskDomainEnum
  partnerOrganisation: Organisation
  taskCreated: string
  taskUpdated: string
  referenceId: number
}

export type Dashboard = {
  pendingTasks: number
  pendingTasksArr: PendingTask[]
  contracts: number
  contractsArr: Contract[]
  organisations: number
  organisationsArr: Organisation[]
  users: number
  usersArr: User[]
  revenue: number
  timesheets: number
  timesheetsArr: Timesheet[]
  timesheetsCreated: number
  timesheetsSigned: number
  message: string
}

export type Menu = {
  id: number
  label: string
  path: string
  icon: ReactNode
}

export type UpdateContractInfo = {
  contractId: number
  units?: number
  startDate: string
  endDate?: string
}
