import AppButton from '@/components/AppButton'
import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import { CreateCommissionTermsParams } from '@/graphql/types'
import useCommissionTerms from '@/hooks/useCommissionTerms'
import useConnections from '@/hooks/useConnections'
import useOrganisationType from '@/hooks/useOrganisationType'
import { showAlert } from '@/redux/reducers/common.slice'
import { CommissionTerms as CommissionTermsItem, Connection } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateCommissionTermsDialog from './components/CreateCommissionTermsDialog'
import TableCommissionTerms from './components/TableCommissionTerms'

const CommissionTerms: React.FC = () => {
  const { getListCommissionTerms, refetch, createCommissionTerms } = useCommissionTerms()
  const { getListConnections } = useConnections()

  const dispatch = useDispatch()
  const { isPlatform } = useOrganisationType()

  const [data, setData] = useState<CommissionTermsItem[]>([])
  const [listConnections, setListConnections] = useState<Connection[]>([])
  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  useEffect(() => {
    getListCommissionTerms({
      onSuccess: response => {
        setData(response.commissionTerms)
      },
    })
    if (isPlatform) {
      getListConnections({
        onSuccess: response => {
          setListConnections(response.connections)
        },
      })
    }
  }, [])

  const onRefresh = () => {
    refetch().then(response => {
      setData(response.data.commissionTerms)
    })
  }

  const onCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const onCreateCommissionTerms = (params: CreateCommissionTermsParams) => {
    createCommissionTerms({
      variables: params,
      onSuccess: () => {
        onCloseCreateDialog()
        dispatch(showAlert({ message: 'Create Commission Terms successfully', type: 'success' }))
        onRefresh()
      },
    })
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Commission Terms' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <Stack direction='row' justifyContent='space-between' mb={3}>
            <div className='text-2xl font-semibold'>List Commission Terms</div>

            {isPlatform && (
              <AppButton onClick={() => setOpenCreateDialog(true)}>
                Create Commission Terms
              </AppButton>
            )}
          </Stack>

          <TableCommissionTerms data={data} onRefresh={onRefresh} />
        </div>

        <CreateCommissionTermsDialog
          open={openCreateDialog}
          onClose={onCloseCreateDialog}
          onCreate={onCreateCommissionTerms}
          listConnections={listConnections}
        />
      </div>
    </div>
  )
}

export default CommissionTerms
