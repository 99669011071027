import SideBar from '@/components/SideBar'
import useDashboard from '@/hooks/useDashboard'
import { ContractStatusEnum, Dashboard, OrganisationTypeEnum, TimesheetStatusEnum } from '@/types'
import React, { useEffect, useState } from 'react'
import Contracts from '../Contracts'
import PendingTasks from '../PendingTasks'
import Timesheets from '../Timesheets'
import Registered from './Registered'

const PlatformDashboard: React.FC = () => {
  const { getPlatformDashboard } = useDashboard()

  const [data, setData] = useState<Dashboard>()
  const [numberExperts, setNumberExperts] = useState(0)
  const [numberSponsors, setNumberSponsors] = useState(0)
  const [numberTimesheetsCreated, setNumberTimesheetsCreated] = useState(0)
  const [numberTimesheetsSigned, setNumberTimesheetsSigned] = useState(0)

  useEffect(() => {
    getPlatformDashboard({
      onSuccess: response => {
        setData(response.dashboard)
        setNumberExperts(
          response.dashboard?.organisationsArr?.filter(
            item => item.type === OrganisationTypeEnum.EXPERT,
          ).length || 0,
        )
        setNumberSponsors(
          response.dashboard?.organisationsArr?.filter(
            item => item.type === OrganisationTypeEnum.SPONSOR,
          ).length || 0,
        )
        setNumberTimesheetsCreated(
          response.dashboard?.timesheetsArr?.filter(
            item => item.status === TimesheetStatusEnum.CREATED,
          ).length || 0,
        )
        setNumberTimesheetsSigned(
          response.dashboard?.timesheetsArr?.filter(
            item => item.status === TimesheetStatusEnum.SPONSOR_SIGNED,
          ).length || 0,
        )
      },
    })
  }, [])

  return (
    <div className='w-full'>
      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] p-8'>
          <div className='grid grid-cols-1 xl:grid-cols-4 xs:grid-cols-2 gap-6'>
            <div className='border border-gray-300 bg-white rounded-lg p-6'>
              <div className='text-base font-medium'>Expert / Sponsor</div>
              <div className='mt-6 text-center text-4xl font-semibold'>
                {numberExperts}/{numberSponsors}
              </div>
            </div>
            <div className='border border-gray-300 bg-white rounded-lg p-6'>
              <div className='text-base font-medium'>Contracts Active</div>
              <div className='mt-6 text-center text-4xl font-semibold'>
                {
                  data?.contractsArr?.filter(item => item.status === ContractStatusEnum.ACTIVE)
                    .length
                }
              </div>
            </div>
            <div className='border border-gray-300 bg-white rounded-lg p-6'>
              <div className='text-base font-medium'>Pending Tasks</div>
              <div className='mt-6 text-center text-4xl font-semibold'>{data?.pendingTasks}</div>
            </div>
            <div className='border border-gray-300 bg-white rounded-lg p-6'>
              <div className='text-base font-medium'>Timesheets Signed / Created</div>
              <div className='mt-6 text-center text-4xl font-semibold'>
                {numberTimesheetsSigned}/{numberTimesheetsCreated}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6'>
            <Registered organisations={data?.organisationsArr || []} />

            <Contracts contracts={data?.contractsArr || []} />

            <PendingTasks pendingTasks={data?.pendingTasksArr || []} />

            <Timesheets timesheets={data?.timesheetsArr || []} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(PlatformDashboard)
