import { hideLoading, showAlert, showLoading } from '@/redux/reducers/common.slice'
import { ApolloError, LazyQueryHookOptions, OperationVariables, useLazyQuery } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { DocumentNode } from 'graphql'
import { useDispatch } from 'react-redux'

interface HandleLazyQueryParams<TData, TVariables extends OperationVariables>
  extends LazyQueryHookOptions<TData, TVariables> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
}

interface LazyQueryOptions<TData, TVariables> {
  variables?: TVariables
  onSuccess?: (data: TData) => void
  onError?: (error: ApolloError) => void
}

const useCustomLazyQuery = <TData, TVariables extends OperationVariables = {}>({
  query,
  ...options
}: HandleLazyQueryParams<TData, TVariables>) => {
  const dispatch = useDispatch()

  const [executeQuery, result] = useLazyQuery<TData, TVariables>(query, {
    ...options,
  })

  const performQuery = async (opts?: LazyQueryOptions<TData, TVariables>) => {
    dispatch(showLoading())
    try {
      const { data, error } = await executeQuery({ variables: opts?.variables })
      if (error && error.message) {
        dispatch(showAlert({ message: error.message }))
        if (opts?.onError) {
          // @ts-ignore
          opts.onError(new ApolloError({ graphQLErrors: [error] }))
        }
      } else if (data && opts?.onSuccess) {
        opts.onSuccess(data)
      }
    } catch (error) {
      if (opts?.onError) {
        opts.onError(error as ApolloError)
      }
    } finally {
      dispatch(hideLoading())
    }
  }

  return [performQuery, result] as const
}

export default useCustomLazyQuery
