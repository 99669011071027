import AppButton from '@/components/AppButton'
import Tooltip from '@/components/Tooltip'
import { FORMAT_TIME } from '@/constants'
import { AddOccupationParams, UpdateOccupationParams } from '@/graphql/types'
import useAccount from '@/hooks/useAccount'
import { setProfile, showAlert } from '@/redux/reducers/common.slice'
import { Occupation, User } from '@/types'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import OccupationDialog from './OccupationDialog'

type Props = {
  profile: User
  viewOnly?: boolean
}

const Occupations: React.FC<Props> = ({ profile, viewOnly }) => {
  const { refetch, createOccupation, updateOccupation } = useAccount()

  const dispatch = useDispatch()

  const [dialogMode, setDialogMode] = useState<'create' | 'update' | 'off'>('off')
  const [occupation, setOccupation] = useState<Occupation | undefined>(undefined)

  const onCloseDialog = () => {
    setDialogMode('off')
  }

  const onCreateOccupation = (params: AddOccupationParams) => {
    createOccupation({
      variables: params,
      onSuccess: () => {
        onCloseDialog()
        dispatch(showAlert({ message: 'Add Occupation successfully', type: 'success' }))
        refetch().then(response => {
          dispatch(setProfile(response.data.profile))
        })
      },
    })
  }

  const onSelectOccupation = (item: Occupation) => () => {
    setOccupation(item)
    setDialogMode('update')
  }

  const onUpdateOccupation = (
    title?: string,
    position?: string,
    fromDate?: string,
    toDate?: string,
    summary?: string,
    company?: string,
    country?: string,
    image?: string,
  ) => {
    if (occupation) {
      const params: UpdateOccupationParams = {
        id: occupation.id,
        title,
        position,
        fromDate,
        toDate,
        summary,
        company,
        country,
        image,
      }
      updateOccupation({
        variables: params,
        onSuccess: () => {
          setOccupation(undefined)
          onCloseDialog()
          dispatch(showAlert({ message: 'Update Occupation successfully', type: 'success' }))
          refetch().then(response => {
            dispatch(setProfile(response.data.profile))
          })
        },
      })
    }
  }

  return (
    <div className='flex flex-col gap-4 overflow-auto'>
      {!viewOnly && (
        <div className='flex justify-end'>
          <AppButton onClick={() => setDialogMode('create')} startIcon={<ControlPointIcon />}>
            Add Occupation
          </AppButton>
        </div>
      )}

      {!!profile?.bio?.occupations?.length &&
        profile.bio.occupations.map(item => (
          <div key={item.id} className='p-4 rounded-lg border border-l-8 border-l-primary'>
            <div className='flex flex-row items-center justify-between'>
              <div className='text-base font-medium'>{item.title}</div>
              {!viewOnly && (
                <Tooltip title='Update'>
                  <BorderColorIcon
                    onClick={onSelectOccupation(item)}
                    color='primary'
                    sx={{ cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
            </div>
            <div>{item.position}</div>
            <div className='mt-2'>
              <span>{item.company} - </span>
              <span className='text-xs text-gray-500'>
                {`(${moment(item.fromDate).format(FORMAT_TIME.YEAR_MONTH_DAY)} - ${item.toDate ? moment(item.toDate).format(FORMAT_TIME.YEAR_MONTH_DAY) : 'Now'})`}
              </span>
            </div>
            <div className='mt-2'>{item.summary}</div>
          </div>
        ))}

      <OccupationDialog
        open={dialogMode !== 'off'}
        mode={dialogMode as 'create' | 'update'}
        onClose={onCloseDialog}
        onCreate={onCreateOccupation}
        onUpdate={onUpdateOccupation}
        occupation={occupation}
      />
    </div>
  )
}

export default React.memo(Occupations)
