// theme.ts
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#646cff',
    },
    secondary: {
      main: '#0ea5e9', // sky-500
    },
    error: {
      main: '#e11d48', // rose-600
    },
    info: {
      main: '#84cc16', // lime-500
    },
    success: {
      main: '#10b981', // emerald-500
    },
    warning: {
      main: '#f59e0b', // amber-500
    },
  },
})

export default theme
