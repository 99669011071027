import { FORMAT_TIME } from '@/constants'
import { Contract, ContractStatusEnum } from '@/types'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

type Props = {
  contracts: Contract[]
}

type GroupData = {
  week: string
  [status: string]: Contract[] | string
}

const Contracts: React.FC<Props> = ({ contracts }) => {
  const [data, setData] = useState<GroupData[]>([])

  useEffect(() => {
    const groupedByWeekAndStatus: GroupData[] = []
    contracts.forEach(contract => {
      const week = moment(contract.createdAt).format(FORMAT_TIME.YEAR_WEEK)
      let weekGroup = groupedByWeekAndStatus.find(group => group.week === week)

      if (!weekGroup) {
        weekGroup = { week }
        groupedByWeekAndStatus.push(weekGroup)
      }

      if (!weekGroup[contract.status]) {
        weekGroup[contract.status] = []
      }

      ;(weekGroup[contract.status] as Contract[]).push(contract)
    })
    setData(groupedByWeekAndStatus)
  }, [contracts])

  return (
    <div className='bg-white rounded-lg p-4'>
      <div className='text-center text-lg font-medium'>Contracts</div>
      <ReactApexChart
        type='bar'
        options={{
          colors: ['#43aa8b', '#ff99c8'],
          chart: {
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            show: true,
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: data.map(item => item.week),
          },
        }}
        series={[
          {
            name: ContractStatusEnum.CREATED,
            data: data.map(item => item[ContractStatusEnum.CREATED]?.length || 0),
          },
          {
            name: ContractStatusEnum.ACTIVE,
            data: data.map((item: any) => item[ContractStatusEnum.ACTIVE]?.length || 0),
          },
        ]}
      />
    </div>
  )
}

export default React.memo(Contracts)
