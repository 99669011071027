import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import { FORMAT_TIME } from '@/constants'
import usePendingTasks from '@/hooks/usePendingTasks'
import { PendingTask, PendingTaskDomainEnum } from '@/types'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserInvitationDialog from './components/UserInvitationDialog'

const PendingTasks: React.FC = () => {
  const navigate = useNavigate()
  const { getListPendingTasks } = usePendingTasks()

  const [data, setData] = useState<PendingTask[]>([])
  const [showDialog, setShowDialog] = useState(false)
  const [selectdItem, setSelectedItem] = useState<PendingTask | undefined>(undefined)

  const onCloseDialog = () => {
    setSelectedItem(undefined)
    setShowDialog(false)
  }

  useEffect(() => {
    getListPendingTasks({
      onSuccess: response => {
        setData(response.dashboard.pendingTasksArr)
      },
    })
  }, [])

  const onClickTask = (task: PendingTask) => () => {
    switch (task.check) {
      case PendingTaskDomainEnum.ACTIVE_COMMISSION_TERMS:
        navigate('/commission-terms')
        return
      case PendingTaskDomainEnum.CONTRACT_SIGNING:
        navigate('/contracts')
        return
      case PendingTaskDomainEnum.TIMESHEET_SIGNING:
        navigate('/timesheets')
        return
      case PendingTaskDomainEnum.USER_INVITATION:
        setSelectedItem(task)
        setShowDialog(true)
        return
    }
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Pending Tasks' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <div className='text-2xl font-semibold mb-6'>List Pending Tasks</div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow sx={{ background: '#F7F7F7' }}>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Partner Org</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Updated Date</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => (
                    <TableRow key={index.toString()}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.partnerOrganisation?.name}</TableCell>
                      <TableCell>
                        {moment(item.taskUpdated).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell>{item.check}</TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' justifyContent='end' spacing={1}>
                          <VisibilityOutlinedIcon
                            onClick={onClickTask(item)}
                            color='primary'
                            sx={{ cursor: 'pointer' }}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <div className='flex w-full flex-col items-center py-14'>
                        <label className='mb-1 mt-4 font-medium'>No data found</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <UserInvitationDialog
          open={showDialog}
          onClose={onCloseDialog}
          task={selectdItem as PendingTask}
        />
      </div>
    </div>
  )
}

export default PendingTasks
