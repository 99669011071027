import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import SideBar from '@/components/SideBar'
import TopBar from '@/components/TopBar'
import { FORMAT_TIME } from '@/constants'
import { CreateTimesheetParams, UpdateTimesheetParams } from '@/graphql/types'
import useOrganisationType from '@/hooks/useOrganisationType'
import useTimesheets from '@/hooks/useTimesheets'
import { showAlert } from '@/redux/reducers/common.slice'
import { Timesheet, TimesheetStatusEnum } from '@/types'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DrawIcon from '@mui/icons-material/Draw'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateTimesheetDialog from './components/CreateTimesheetDialog'
import DetailTimesheetDialog from './components/DetailTimesheetDialog'
import Status from './components/Status'
import UpdateTimesheetDialog from './components/UpdateTimesheetDialog'

const Timesheets: React.FC = () => {
  const { isExpert, isSponsor } = useOrganisationType()
  const { getListTimesheets, refetch, createTimesheet, updateTimesheet, signTimesheet } =
    useTimesheets()
  const dispatch = useDispatch()

  const [data, setData] = useState<Timesheet[]>([])
  const [openCreateDialog, setOpenCreateDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Timesheet | undefined>(undefined)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openSignDialog, setOpenSignDialog] = useState(false)
  const [openDetailDialog, setOpenDetailDialog] = useState(false)

  useEffect(() => {
    getListTimesheets({
      onSuccess: response => {
        setData(response.timesheets)
      },
    })
  }, [])

  const onCloseCreateDialog = () => {
    setOpenCreateDialog(false)
  }

  const onCloseUpdateDialog = () => {
    setSelectedItem(undefined)
    setOpenUpdateDialog(false)
  }

  const onCloseSignDialog = () => {
    setSelectedItem(undefined)
    setOpenSignDialog(false)
  }

  const onCloseDetailDialog = () => {
    setSelectedItem(undefined)
    setOpenDetailDialog(false)
  }

  const onCreateTimesheet = (params: CreateTimesheetParams) => {
    createTimesheet({
      variables: params,
      onSuccess: () => {
        onCloseCreateDialog()
        dispatch(showAlert({ message: 'Create Timesheet successfully', type: 'success' }))
        refetch().then(response => {
          setData(response.data.timesheets)
        })
      },
    })
  }

  const onUpdateContract = (
    deliveryDate?: string,
    units?: number,
    summary?: {
      tasks: string[]
    },
  ) => {
    if (selectedItem?.id) {
      const params: UpdateTimesheetParams = {
        timesheetId: selectedItem.id,
        deliveryDate,
        units,
        summary,
      }
      updateTimesheet({
        variables: params,
        onSuccess: () => {
          onCloseUpdateDialog()
          dispatch(showAlert({ message: 'Update Timesheet successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.timesheets)
          })
        },
      })
    }
  }

  const onSignTimesheet = () => {
    if (selectedItem?.id) {
      signTimesheet({
        variables: { timesheetId: selectedItem.id },
        onSuccess: () => {
          onCloseSignDialog()
          dispatch(showAlert({ message: 'Sign Timesheet successfully', type: 'success' }))
          refetch().then(response => {
            setData(response.data.timesheets)
          })
        },
      })
    }
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Timesheets' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <Stack direction='row' justifyContent='space-between' mb={3}>
            <div className='text-2xl font-semibold'>List Timesheets</div>

            {isExpert && (
              <AppButton onClick={() => setOpenCreateDialog(true)}>Create Timesheet</AppButton>
            )}
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow sx={{ background: '#F7F7F7' }}>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Expert</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Delivery Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Units
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Created Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Updated Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.expertUser?.organisation?.name}</TableCell>
                      <TableCell align='right'>
                        {moment(item.deliveryDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='right'>{item.units}</TableCell>
                      <TableCell align='right'>
                        {moment(item.createdAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='right'>
                        {moment(item.updatedAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='center'>
                        <Status status={item.status} />
                      </TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' justifyContent='end' spacing={1}>
                          {isSponsor && item.status === TimesheetStatusEnum.CREATED && (
                            <Tooltip title='Sign'>
                              <DrawIcon
                                onClick={() => {
                                  setSelectedItem(item)
                                  setOpenSignDialog(true)
                                }}
                                color='primary'
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}

                          {isExpert && item.status === TimesheetStatusEnum.CREATED && (
                            <Tooltip title='Update'>
                              <BorderColorIcon
                                onClick={() => {
                                  setSelectedItem(item)
                                  setOpenUpdateDialog(true)
                                }}
                                color='primary'
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}

                          <Tooltip title='View'>
                            <VisibilityOutlinedIcon
                              onClick={() => {
                                setSelectedItem(item)
                                setOpenDetailDialog(true)
                              }}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div className='flex w-full flex-col items-center py-14'>
                        <label className='font-medium'>No data found</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <CreateTimesheetDialog
          open={openCreateDialog}
          onClose={onCloseCreateDialog}
          onCreate={onCreateTimesheet}
        />

        {!!selectedItem && (
          <UpdateTimesheetDialog
            open={openUpdateDialog}
            onClose={onCloseUpdateDialog}
            onUpdate={onUpdateContract}
            timesheet={selectedItem}
          />
        )}

        {!!selectedItem && (
          <DetailTimesheetDialog
            open={openDetailDialog}
            onClose={onCloseDetailDialog}
            timesheet={selectedItem}
          />
        )}

        <AppDialog
          open={openSignDialog}
          onClose={onCloseSignDialog}
          title='Sign Timesheet'
          ButtonNegative={
            <AppButton onClick={onCloseSignDialog} color='error'>
              Cancel
            </AppButton>
          }
          ButtonPositive={<AppButton onClick={onSignTimesheet}>Sign</AppButton>}
        >
          <div>Are you sure you want to sign this Timesheet</div>
        </AppDialog>
      </div>
    </div>
  )
}

export default Timesheets
