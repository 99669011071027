import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import { FORMAT_TIME } from '@/constants'
import useContracts from '@/hooks/useContracts'
import { Contract, Timesheet } from '@/types'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Status from './Status'

type Props = {
  open: boolean
  onClose: () => void
  timesheet: Timesheet
}

const DetailTimesheetDialog: React.FC<Props> = ({ open, onClose, timesheet }) => {
  const { getDetailContract } = useContracts()
  const [dataContract, setDataContract] = useState<Contract>()

  useEffect(() => {
    if (timesheet) {
      getDetailContract({
        variables: { contractId: timesheet.contract.id },
        onSuccess: response => {
          setDataContract(response.contract)
        },
      })
    }
  }, [timesheet])

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Detail Timesheet'
      size='md'
      ButtonPositive={<AppButton onClick={onClose}>Close</AppButton>}
    >
      <div className='w-full grid grid-cols-2 gap-2 font-medium'>
        <div>Sponsor:</div>
        <div>{dataContract?.sponsorOrganisation?.name}</div>
        <div>Expert:</div>
        <div>{dataContract?.expertOrganisation?.name}</div>
        <div>Status:</div>
        <div>
          <Status status={timesheet.status} />
        </div>
        <div>Units:</div>
        <div>{timesheet.units}</div>
        <div>Summary:</div>
        <div>{timesheet.summary.tasks.join('; ')}</div>
        <div>Delivery Date:</div>
        <div>{moment(timesheet.deliveryDate).format(FORMAT_TIME.YEAR_MONTH_DAY)}</div>
        <div>Created At:</div>
        <div>{moment(timesheet.createdAt).format(FORMAT_TIME.YEAR_MONTH_DAY_TIME)}</div>
        <div>Updated At:</div>
        <div>
          {timesheet.updatedAt &&
            moment(timesheet.updatedAt).format(FORMAT_TIME.YEAR_MONTH_DAY_TIME)}
        </div>
      </div>
    </AppDialog>
  )
}

export default React.memo(DetailTimesheetDialog)
