import AppButton from '@/components/AppButton'
import AppDatePicker, { AppDatePickerRef } from '@/components/AppDatePicker'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import AppSelect, { AppSelectRef } from '@/components/AppSelect'
import { CreateContractParams } from '@/graphql/types'
import useExperts from '@/hooks/useExperts'
import useOrganisationType from '@/hooks/useOrganisationType'
import { BillingTimeUnitEnum, ContractPaymentTermsEnum, User } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onCreate: (params: CreateContractParams) => void
}

const CreateContractDialog: React.FC<Props> = ({ open, onClose, onCreate }) => {
  const { getListExperts } = useExperts()
  const { isSponsor } = useOrganisationType()

  const [listExperts, setListExperts] = useState<User[]>([])

  const refExpert = useRef<AppSelectRef | null>(null)
  const refBillingTimeUnit = useRef<AppSelectRef | null>(null)
  const refUnits = useRef<AppInputRef | null>(null)
  const refStartDate = useRef<AppDatePickerRef | null>(null)
  const refEndDate = useRef<AppDatePickerRef | null>(null)
  const refPaymentTerms = useRef<AppSelectRef | null>(null)

  useEffect(() => {
    if (isSponsor) {
      getListExperts({
        onSuccess: response => {
          setListExperts(response.experts)
        },
      })
    }
  }, [])

  const handleCreateContract = () => {
    const expertUserId = refExpert.current?.getValue() ? Number(refExpert.current?.getValue()) : 0
    const billingTimeUnit = refBillingTimeUnit.current?.getValue() as BillingTimeUnitEnum
    const units = refUnits.current?.getValue() ? Number(refUnits.current?.getValue()) : undefined
    const startDate = refStartDate.current?.getValue() || ''
    const endDate = refEndDate.current?.getValue()
    const paymentTerms = refPaymentTerms.current?.getValue() as ContractPaymentTermsEnum

    if (!expertUserId || !billingTimeUnit || !startDate || !paymentTerms) {
      !expertUserId && refExpert.current?.setError('Please select Expert')
      !billingTimeUnit && refBillingTimeUnit.current?.setError('Please select Billing Time Unit')
      !startDate && refStartDate.current?.setError('Please select Start Date')
      !paymentTerms && refPaymentTerms.current?.setError('Please select Payment Terms')
    } else {
      const params: CreateContractParams = {
        expertUserId,
        billingTimeUnit,
        units,
        startDate,
        endDate,
        paymentTerms,
      }
      onCreate(params)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Create Contract'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleCreateContract}>Create</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppSelect
          list={listExperts.map(item => ({
            value: item.id,
            label: item.organisation?.name,
          }))}
          label='Expert'
          required
          ref={refExpert}
        />

        <AppSelect
          list={[
            { value: BillingTimeUnitEnum.HOUR, label: 'Hour' },
            { value: BillingTimeUnitEnum.HALF_DAY, label: 'Half day' },
            { value: BillingTimeUnitEnum.DAY, label: 'Day' },
          ]}
          label='Billing Time Unit'
          required
          ref={refBillingTimeUnit}
        />

        <AppInput label='Units' type='number' ref={refUnits} />

        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <AppDatePicker label='Start Date' required ref={refStartDate} />
          <AppDatePicker label='End Date' ref={refEndDate} />
        </Stack>

        <AppSelect
          list={[
            { value: ContractPaymentTermsEnum.PAY_PER_TIMESHEET, label: 'Pay per timesheet' },
            { value: ContractPaymentTermsEnum.MONTHLY, label: 'Monthly' },
          ]}
          label='Payment Terms'
          required
          ref={refPaymentTerms}
        />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(CreateContractDialog)
