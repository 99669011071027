import useOrganisationType from '@/hooks/useOrganisationType'
import { User } from '@/types'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import Occupations from './Occupations'

type Props = {
  profile: User
  viewOnly?: boolean
}

enum TAB_VALUE {
  ACCOUNT_SETTING = 1,
  BILLING = 2,
  OCCUPATION = 3,
}

const listPlatformTabs = [
  {
    value: TAB_VALUE.ACCOUNT_SETTING,
    label: 'Account Setting',
  },
  {
    value: TAB_VALUE.BILLING,
    label: 'Billing',
  },
]

const listExpertTabs = [
  {
    value: TAB_VALUE.ACCOUNT_SETTING,
    label: 'Account Setting',
  },
  {
    value: TAB_VALUE.BILLING,
    label: 'Billing',
  },
  {
    value: TAB_VALUE.OCCUPATION,
    label: 'Occupations',
  },
]

const listSponsorTabs = [
  {
    value: TAB_VALUE.ACCOUNT_SETTING,
    label: 'Account Setting',
  },
  {
    value: TAB_VALUE.BILLING,
    label: 'Billing',
  },
]

const UserTabs: React.FC<Props> = ({ profile, viewOnly }) => {
  const { isPlatform, isExpert, isSponsor } = useOrganisationType()

  const [tab, setTab] = useState<TAB_VALUE>(
    isExpert && viewOnly ? TAB_VALUE.OCCUPATION : TAB_VALUE.ACCOUNT_SETTING,
  )

  const onChangeTab = (event: React.SyntheticEvent, newValue: TAB_VALUE) => {
    setTab(newValue)
    console.log(event)
  }

  return (
    <div className='w-full'>
      <Tabs value={tab} onChange={onChangeTab}>
        {isPlatform &&
          listPlatformTabs.map(item => (
            <Tab
              key={item.value}
              value={item.value}
              label={
                <div className='text-base font-medium capitalize text-black'>{item.label}</div>
              }
            />
          ))}
        {isExpert &&
          (viewOnly
            ? [
                {
                  value: TAB_VALUE.OCCUPATION,
                  label: 'Occupation',
                },
              ]
            : listExpertTabs
          ).map(item => (
            <Tab
              key={item.value}
              value={item.value}
              label={
                <div className='text-base font-medium capitalize text-black'>{item.label}</div>
              }
            />
          ))}
        {isSponsor &&
          listSponsorTabs.map(item => (
            <Tab
              key={item.value}
              value={item.value}
              label={
                <div className='text-base font-medium capitalize text-black'>{item.label}</div>
              }
            />
          ))}
      </Tabs>
      <div className='border-t pt-6'>
        {tab === TAB_VALUE.ACCOUNT_SETTING && <div>Account Settings</div>}
        {tab === TAB_VALUE.BILLING && <div>Billing</div>}
        {tab === TAB_VALUE.OCCUPATION && <Occupations profile={profile} viewOnly={viewOnly} />}
      </div>
    </div>
  )
}

export default React.memo(UserTabs)
