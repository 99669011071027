import AppButton from '@/components/AppButton'
import AppDatePicker, { AppDatePickerRef } from '@/components/AppDatePicker'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import { generateRandomString } from '@/helpers'
import { Timesheet } from '@/types'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import { Stack } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onUpdate: (
    deliveryDate?: string,
    units?: number,
    summary?: {
      tasks: string[]
    },
  ) => void
  timesheet: Timesheet
}

const UpdateTimesheetDialog: React.FC<Props> = ({ open, onClose, onUpdate, timesheet }) => {
  const [summaries, setSummaries] = useState<{ id: string; value: string }[]>([])
  const [errorSummary, setErrorSummary] = useState('')

  const refDeliveryDate = useRef<AppDatePickerRef | null>(null)
  const refUnits = useRef<AppInputRef | null>(null)
  const refSummaries = useRef<Array<AppInputRef | null>>([])

  useEffect(() => {
    if (timesheet) {
      setSummaries(
        timesheet.summary.tasks.map(item => ({ id: generateRandomString(), value: item })),
      )
    }
  }, [timesheet])

  const addSummary = () => {
    setSummaries([...summaries, { id: generateRandomString(), value: '' }])
    setErrorSummary('')
  }

  const removeSummary = (id: string) => () => {
    const _summaries = summaries.filter(item => item.id !== id)
    setSummaries(_summaries)
  }

  const handleUpdateTimesheet = () => {
    const deliveryDate = refDeliveryDate.current?.getValue() || ''
    const units = refUnits.current?.getValue() ? Number(refUnits.current?.getValue()) : undefined
    const listTask = refSummaries.current.map(item => item?.getValue()).filter(item => item)

    let error = false
    refSummaries.current.map(item => {
      if (item) {
        const value = item.getValue()
        if (!value || (value && !value.trim())) {
          item.setError('Please enter Summary')
          error = true
        }
      }
    })

    if (!units || !deliveryDate || !listTask.length || error) {
      !units && refUnits.current?.setError('Please enter Units')
      !deliveryDate && refDeliveryDate.current?.setError('Please select Delivery Date')
      !listTask.length && setErrorSummary('Please add at least 1 summary')
    } else {
      // @ts-ignore
      onUpdate(deliveryDate, units, { tasks: listTask })
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Update Timesheet'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleUpdateTimesheet}>Update</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppDatePicker
          label='Delivery Date'
          defaultValue={moment(timesheet.deliveryDate)}
          ref={refDeliveryDate}
        />

        <AppInput
          label='Units'
          type='number'
          defaultValue={timesheet.units ? timesheet.units.toString() : ''}
          ref={refUnits}
        />

        <div>
          <div>
            Summary <AddCircleOutlineIcon onClick={addSummary} className='cursor-pointer' />
          </div>
          {!!errorSummary && <div className='text-xs text-rose-600'>{errorSummary}</div>}
        </div>
        <div className='max-h-56 overflow-auto'>
          {summaries.map((item, index) => (
            <div key={item.id} className='flex flex-row items-center gap-2 mb-2'>
              <AppInput
                defaultValue={item.value}
                ref={ref => (refSummaries.current[index] = ref)}
              />

              <DeleteIcon
                onClick={removeSummary(item.id)}
                color='error'
                className='cursor-pointer'
              />
            </div>
          ))}
        </div>
      </Stack>
    </AppDialog>
  )
}

export default React.memo(UpdateTimesheetDialog)
