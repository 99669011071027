import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import Zoom from '@mui/material/Zoom'
import { styled } from '@mui/material/styles'
import React, { ReactNode } from 'react'

type Props = {
  title: string
  children: ReactNode
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement='top'
    TransitionComponent={Zoom}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}))

const AppTooltip: React.FC<Props> = ({ title, children }) => {
  return (
    <BootstrapTooltip title={title}>
      <div>{children}</div>
    </BootstrapTooltip>
  )
}

export default React.memo(AppTooltip)
