import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import { GET_CONNECTIONS } from '@/graphql/queries'
import { GetConnectionsResponse } from '@/graphql/types'

const useConnections = () => {
  const [getListConnections, { refetch }] = useCustomLazyQuery<GetConnectionsResponse>({
    query: GET_CONNECTIONS,
  })

  return { getListConnections, refetch }
}

export default useConnections
