import TopBar from '@/components/TopBar'
import { RootState } from '@/redux/store'
import { OrganisationTypeEnum } from '@/types'
import React from 'react'
import { useSelector } from 'react-redux'
import ExpertDashboard from './components/ExpertDashboard'
import PlatformDashboard from './components/PlatformDashboard'
import SponsorDashboard from './components/SponsorDashboard'

const Dashboard: React.FC = () => {
  const { profile } = useSelector((state: RootState) => state.common)

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Dashboard' />

      {profile.organisation?.type === OrganisationTypeEnum.PLATFORM && <PlatformDashboard />}

      {profile.organisation?.type === OrganisationTypeEnum.EXPERT && <ExpertDashboard />}

      {profile.organisation?.type === OrganisationTypeEnum.SPONSOR && <SponsorDashboard />}
    </div>
  )
}

export default Dashboard
