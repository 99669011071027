import { FORMAT_TIME } from '@/constants'
import { DatePicker } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

type Props = {
  label?: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  defaultValue?: Moment | null
  disableFuture?: boolean
  disablePast?: boolean
  shouldDisableDate?: (date: Moment) => boolean
}

export type AppDatePickerRef = {
  clear: () => void
  getValue: () => string | undefined
  setError: (msg: string) => void
}

const AppDatePicker: React.ForwardRefRenderFunction<AppDatePickerRef, Props> = (props, ref) => {
  const {
    label,
    readOnly,
    required,
    disabled,
    defaultValue,
    disableFuture,
    disablePast,
    shouldDisableDate,
  } = props
  const [value, setValue] = useState<Moment | null>(defaultValue || null)
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        setValue(null)
        setErrorMessage('')
      },
      getValue: () => (value ? moment(value).format(FORMAT_TIME.YEAR_MONTH_DAY) : undefined),
      setError: (msg: string) => {
        setErrorMessage(msg)
      },
    }),
    [value],
  )

  const onChangeValue = (_value: Moment | null) => {
    setValue(_value)
    setErrorMessage('')
  }

  return (
    <DatePicker
      label={label}
      sx={{ width: '100%' }}
      value={value}
      onChange={onChangeValue}
      slotProps={{
        textField: {
          helperText: errorMessage,
          error: !!errorMessage,
          required: required,
          inputProps: { readOnly: true },
        },
      }}
      disabled={disabled}
      readOnly={readOnly}
      disableFuture={disableFuture}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      format={FORMAT_TIME.YEAR_MONTH_DAY}
    />
  )
}

export default React.memo(forwardRef(AppDatePicker))
