import { ContractPaymentTermsEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  paymentTerms: ContractPaymentTermsEnum
}

const PaymentTerms: React.FC<Props> = ({ paymentTerms }) => {
  switch (paymentTerms) {
    case ContractPaymentTermsEnum.PAY_PER_TIMESHEET:
      return (
        <Chip
          label='PAY PER TIMESHEET'
          color='secondary'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractPaymentTermsEnum.MONTHLY:
      return (
        <Chip
          label='MONTHLY'
          color='success'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(PaymentTerms)
