import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React, { ReactNode } from 'react'

type Props = {
  open: boolean
  title: string
  onClose: () => void
  children: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  ButtonNegative?: ReactNode
  ButtonPositive?: ReactNode
}

const AppDialog: React.FC<Props> = ({
  open,
  title,
  onClose,
  size = 'sm',
  children,
  ButtonNegative,
  ButtonPositive,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={size} fullWidth>
      <DialogTitle fontWeight={600}>{title}</DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        {ButtonNegative}
        {ButtonPositive}
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AppDialog)
