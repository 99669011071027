import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React, { ReactNode, forwardRef, useImperativeHandle, useState } from 'react'

type Props = {
  label?: string
  placeholder?: string
  size?: 'small' | 'medium'
  startAdornment?: string | ReactNode
  endAdornment?: string | ReactNode
  multiline?: boolean
  disabled?: boolean
  type?: string
  readOnly?: boolean
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  defaultValue?: string
}

export type AppInputRef = {
  clear: () => void
  getValue: () => string | undefined
  setError: (msg: string) => void
}

const AppInput: React.ForwardRefRenderFunction<AppInputRef, Props> = (props, ref) => {
  const {
    label,
    placeholder,
    size = 'medium',
    startAdornment,
    endAdornment,
    multiline,
    disabled,
    type,
    readOnly,
    required,
    variant = 'outlined',
    defaultValue,
  } = props
  const [value, setValue] = useState<string>(defaultValue || '')
  const [errorMessage, setErrorMessage] = useState('')

  useImperativeHandle(
    ref,
    () => ({
      clear: () => {
        setValue('')
        setErrorMessage('')
      },
      getValue: () => value || undefined,
      setError: (msg: string) => {
        setErrorMessage(msg)
      },
    }),
    [value],
  )

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setErrorMessage('')
  }

  return (
    <TextField
      fullWidth
      label={label}
      placeholder={placeholder}
      size={size}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position='start'>{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position='end'>{endAdornment}</InputAdornment>
        ),
        readOnly: readOnly,
      }}
      multiline={multiline}
      rows={multiline ? 3 : 1}
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={disabled}
      type={type}
      required={required}
      variant={variant}
      value={value}
      onChange={onChangeValue}
    />
  )
}

export default React.memo(forwardRef(AppInput))
