import { CommissionTermsStatusEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  status: CommissionTermsStatusEnum
}

const Status: React.FC<Props> = ({ status }) => {
  switch (status) {
    case CommissionTermsStatusEnum.CREATED:
      return (
        <Chip
          label='CREATED'
          color='info'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsStatusEnum.PLATFORM_SIGNED:
      return (
        <Chip
          label='PLATFORM SIGNED'
          color='warning'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsStatusEnum.SIGNED:
      return (
        <Chip
          label='SIGNED'
          color='secondary'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsStatusEnum.ACTIVE:
      return (
        <Chip
          label='ACTIVE'
          color='success'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsStatusEnum.INACTIVE:
      return (
        <Chip label='INACTIVE' size='small' sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }} />
      )
    case CommissionTermsStatusEnum.EXPIRED:
      return (
        <Chip
          label='EXPIRED'
          color='error'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(Status)
