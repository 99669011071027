import { RootState } from '@/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'

const AppLoading: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.common)

  return (
    <div
      className='fixed left-0 top-0 z-[99999] h-full overflow-hidden'
      style={{ display: loading ? undefined : 'none' }}
    >
      <div className='fixed z-[100000] flex h-full w-full items-center justify-center bg-[#00000050]'>
        <div className='flex h-40 w-40 flex-col items-center justify-center rounded-xl bg-white shadow-xl'>
          <div className='spinner' />
          <div className='mt-4'>Please wait...</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AppLoading)
