import { formatTimestamp } from '@/helpers'
import { RootState } from '@/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'

type Props = {
  message: {
    id: number
    content: string
    senderId: number
    timestamp: string
  }
}

const MessageItem: React.FC<Props> = ({ message }) => {
  const { profile } = useSelector((state: RootState) => state.common)

  if (profile.id === message.senderId) {
    return (
      <div className='mb-1 flex justify-end'>
        <div className='max-w-96 lg:max-w-[55%] bg-stone-200 mx-4 px-3 py-1 rounded-t-xl rounded-bl-xl'>
          <div>{message.content}</div>
          <div className='text-left text-[10px] text-gray-500'>
            {formatTimestamp(message.timestamp)}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='mb-1 flex justify-start'>
      <div className='max-w-96 lg:max-w-[55%] bg-stone-200 mx-4 px-3 py-1 rounded-t-xl rounded-br-xl'>
        <div>{message.content}</div>
        <div className='text-right text-[10px] text-gray-500'>
          {formatTimestamp(message.timestamp)}
        </div>
      </div>
    </div>
  )
}

export default React.memo(MessageItem)
