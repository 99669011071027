import { TimesheetStatusEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  status: TimesheetStatusEnum
}

const Status: React.FC<Props> = ({ status }) => {
  switch (status) {
    case TimesheetStatusEnum.CREATED:
      return <Chip label='CREATED' size='small' sx={{ fontWeight: 600, fontSize: 12 }} />
    case TimesheetStatusEnum.SPONSOR_SIGNED:
      return (
        <Chip
          label='SPONSOR_SIGNED'
          color='success'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case TimesheetStatusEnum.INVOICED:
      return (
        <Chip
          label='INVOICED'
          color='info'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case TimesheetStatusEnum.FULLFILLED:
      return (
        <Chip
          label='FULLFILLED'
          color='primary'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case TimesheetStatusEnum.CANCELED:
      return (
        <Chip
          label='CANCELED'
          color='error'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(Status)
