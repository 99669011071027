import { ContractStatusEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  status: ContractStatusEnum
}

const Status: React.FC<Props> = ({ status }) => {
  switch (status) {
    case ContractStatusEnum.DRAFT:
      return <Chip label='DRAFT' size='small' sx={{ fontWeight: 600, fontSize: 12 }} />
    case ContractStatusEnum.CREATED:
      return <Chip label='CREATED' size='small' sx={{ fontWeight: 600, fontSize: 12 }} />
    case ContractStatusEnum.PENDING_SIGNATURES:
      return (
        <Chip
          label='PENDING SIGNATURES'
          color='warning'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.SPONSOR_SIGNED:
      return (
        <Chip
          label='SPONSOR SIGNED'
          color='info'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.EXPERT_SIGNED:
      return (
        <Chip
          label='EXPERT SIGNED'
          color='info'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.ACTIVE:
      return (
        <Chip
          label='ACTIVE'
          color='success'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.CANCELED:
      return (
        <Chip
          label='CANCELED'
          color='error'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.FULLFILLED:
      return (
        <Chip
          label='FULLFILLED'
          color='primary'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.PARTIALLY_FULLFILLED:
      return (
        <Chip
          label='PARTIALLY FULLFILLED'
          color='secondary'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
    case ContractStatusEnum.CLOSED:
      return (
        <Chip
          label='CLOSED'
          color='error'
          size='small'
          sx={{ color: '#fff', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(Status)
