import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import useCustomMutation from '@/graphql/hooks/useCustomMutation'
import { CREATE_TIMESHEET, SIGN_TIMESHEET, UPDATE_TIMESHEET } from '@/graphql/mutations'
import { GET_TIMESHEETS } from '@/graphql/queries'
import {
  CreateTimesheetParams,
  CreateTimesheetResponse,
  GetTimesheetsResponse,
  SignTimesheetParams,
  SignTimesheetResponse,
  UpdateTimesheetParams,
  UpdateTimesheetResponse,
} from '@/graphql/types'

const useTimesheets = () => {
  const [getListTimesheets, { refetch }] = useCustomLazyQuery<GetTimesheetsResponse>({
    query: GET_TIMESHEETS,
  })
  const [createTimesheet] = useCustomMutation<CreateTimesheetResponse, CreateTimesheetParams>({
    mutation: CREATE_TIMESHEET,
  })
  const [updateTimesheet] = useCustomMutation<UpdateTimesheetResponse, UpdateTimesheetParams>({
    mutation: UPDATE_TIMESHEET,
  })
  const [signTimesheet] = useCustomMutation<SignTimesheetResponse, SignTimesheetParams>({
    mutation: SIGN_TIMESHEET,
  })

  return { getListTimesheets, refetch, createTimesheet, updateTimesheet, signTimesheet }
}

export default useTimesheets
