import { CommissionTermsFrequencyTypeEnum } from '@/types'
import Chip from '@mui/material/Chip'
import React from 'react'

type Props = {
  frequencyType: CommissionTermsFrequencyTypeEnum
}

const FrequencyType: React.FC<Props> = ({ frequencyType }) => {
  switch (frequencyType) {
    case CommissionTermsFrequencyTypeEnum.ONE_TIME:
      return (
        <Chip
          label='ONE TIME'
          variant='outlined'
          size='small'
          color='warning'
          sx={{ color: 'theme.palette.warning', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsFrequencyTypeEnum.PER_CONTRACT:
      return (
        <Chip
          label='PER CONTRACT'
          variant='outlined'
          size='small'
          color='success'
          sx={{ color: 'theme.palette.success', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsFrequencyTypeEnum.PER_TRANSACTION:
      return (
        <Chip
          label='PER TRANSACTION'
          variant='outlined'
          size='small'
          color='secondary'
          sx={{ color: 'theme.palette.secondary', fontWeight: 600, fontSize: 12 }}
        />
      )
    case CommissionTermsFrequencyTypeEnum.FIVE_CONTRACTS:
      return (
        <Chip
          label='FIVE CONTRACTS'
          variant='outlined'
          size='small'
          color='info'
          sx={{ color: 'theme.palette.info', fontWeight: 600, fontSize: 12 }}
        />
      )
  }
}

export default React.memo(FrequencyType)
