import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import UserInfo from '@/pages/Account/components/UserInfo'
import UserTabs from '@/pages/Account/components/UserTabs'
import { User } from '@/types'
import React from 'react'

type Props = {
  open: boolean
  onClose: () => void
  expertInfo: User
}

const ExpertInfoDialog: React.FC<Props> = ({ open, onClose, expertInfo }) => {
  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='User Info'
      size='md'
      ButtonPositive={<AppButton onClick={onClose}>Close</AppButton>}
    >
      <div className='w-full flex flex-row gap-6'>
        <UserInfo profile={expertInfo} />
        <UserTabs profile={expertInfo} viewOnly />
      </div>
    </AppDialog>
  )
}

export default React.memo(ExpertInfoDialog)
