import { FORMAT_TIME } from '@/constants'
import UserInvitationDialog from '@/pages/PendingTasks/components/UserInvitationDialog'
import { PendingTask, PendingTaskDomainEnum } from '@/types'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  pendingTasks: PendingTask[]
}

const PendingTasks: React.FC<Props> = ({ pendingTasks }) => {
  const navigate = useNavigate()

  const [showDialog, setShowDialog] = useState(false)
  const [selectdItem, setSelectedItem] = useState<PendingTask | undefined>(undefined)

  const onCloseDialog = () => {
    setSelectedItem(undefined)
    setShowDialog(false)
  }

  const onClickTask = (task: PendingTask) => () => {
    switch (task.check) {
      case PendingTaskDomainEnum.ACTIVE_COMMISSION_TERMS:
        navigate('/commission-terms')
        return
      case PendingTaskDomainEnum.CONTRACT_SIGNING:
        navigate('/contracts')
        return
      case PendingTaskDomainEnum.TIMESHEET_SIGNING:
        navigate('/timesheets')
        return
      case PendingTaskDomainEnum.USER_INVITATION:
        setSelectedItem(task)
        setShowDialog(true)
        return
    }
  }

  return (
    <div className='bg-white rounded-lg p-4'>
      <div className='text-center text-lg font-medium mb-6'>Pending tasks</div>

      <div className='max-h-[400px] overflow-auto'>
        {pendingTasks.length ? (
          pendingTasks.map((task, index) => (
            <div
              key={index}
              onClick={onClickTask(task)}
              className='flex flex-row items-center justify-between cursor-pointer px-4 py-2 mb-2 border border-l-8 border-l-primary bg-white rounded-lg'
            >
              <div>
                <div className='text-base font-medium'>{task.partnerOrganisation?.name}</div>
                <div>{task.check}</div>
                <div className='text-xs text-subtitle'>
                  {moment(task.taskUpdated).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                </div>
              </div>
              <ArrowForwardIcon />
            </div>
          ))
        ) : (
          <div>No data found</div>
        )}
      </div>

      <UserInvitationDialog
        open={showDialog}
        onClose={onCloseDialog}
        task={selectdItem as PendingTask}
      />
    </div>
  )
}

export default React.memo(PendingTasks)
