import useCustomLazyQuery from '@/graphql/hooks/useCustomLazyQuery'
import useCustomMutation from '@/graphql/hooks/useCustomMutation'
import {
  ADD_OCCUPATION,
  CONFIRM_INVITE,
  INVITE_EXPERT,
  INVITE_SPONSOR,
  LOGIN,
  UPDATE_OCCUPATION,
  VERIFY_REGISTRATION_CODE,
} from '@/graphql/mutations'
import { GET_PROFILE, GET_USER_BY_ID } from '@/graphql/queries'
import {
  AddOccupationParams,
  AddOccupationResponse,
  ConfirmInviteParams,
  ConfirmInviteResponse,
  GetProfileResponse,
  GetUserByIdParams,
  GetUserByIdResponse,
  InviteParams,
  InviteResponse,
  LoginParams,
  LoginResponse,
  UpdateOccupationParams,
  UpdateOccupationResponse,
  VerifyRegistrationCodeParams,
  VerifyRegistrationCodeResponse,
} from '@/graphql/types'

const useAccount = () => {
  const [login] = useCustomMutation<LoginResponse, LoginParams>({
    mutation: LOGIN,
  })
  const [confirmInvite] = useCustomMutation<ConfirmInviteResponse, ConfirmInviteParams>({
    mutation: CONFIRM_INVITE,
  })
  const [verifyRegistrationCode] = useCustomMutation<
    VerifyRegistrationCodeResponse,
    VerifyRegistrationCodeParams
  >({
    mutation: VERIFY_REGISTRATION_CODE,
  })
  const [getProfile, { refetch }] = useCustomLazyQuery<GetProfileResponse>({
    query: GET_PROFILE,
  })
  const [createOccupation] = useCustomMutation<AddOccupationResponse, AddOccupationParams>({
    mutation: ADD_OCCUPATION,
  })
  const [updateOccupation] = useCustomMutation<UpdateOccupationResponse, UpdateOccupationParams>({
    mutation: UPDATE_OCCUPATION,
  })
  const [inviteExpert] = useCustomMutation<InviteResponse, InviteParams>({
    mutation: INVITE_EXPERT,
  })
  const [inviteSponsor] = useCustomMutation<InviteResponse, InviteParams>({
    mutation: INVITE_SPONSOR,
  })
  const [getUserById] = useCustomLazyQuery<GetUserByIdResponse, GetUserByIdParams>({
    query: GET_USER_BY_ID,
  })

  return {
    login,
    getProfile,
    refetch,
    confirmInvite,
    verifyRegistrationCode,
    createOccupation,
    updateOccupation,
    inviteExpert,
    inviteSponsor,
    getUserById,
  }
}

export default useAccount
