import { hideAlert } from '@/redux/reducers/common.slice'
import { RootState } from '@/redux/store'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AppAlert: React.FC = () => {
  const { alert } = useSelector((state: RootState) => state.common)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideAlert())
    }, 3000)
  }, [alert])

  return (
    <div
      style={{ display: alert.message ? undefined : 'none' }}
      className='fixed right-2 top-2 z-[999999] flex flex-row items-center justify-between rounded bg-white p-4 shadow-xl'
    >
      <span
        className={`mr-12 cursor-default ${
          alert.type === 'error' ? 'text-red-500' : 'text-green-500'
        }`}
      >
        {alert.message}
      </span>

      <span
        id='btn-alert-hide'
        onClick={() => dispatch(hideAlert())}
        className='text-primary hover:cursor-pointer'
      >
        Hide
      </span>
    </div>
  )
}

export default React.memo(AppAlert)
