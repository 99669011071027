import InputText, { InputTextRef } from '@/components/InputText'
import { REGEX_EMAIL } from '@/constants'
import useAccount from '@/hooks/useAccount'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

const ConfirmInvite: React.FC = () => {
  const { confirmInvite, verifyRegistrationCode } = useAccount()

  const { token } = useParams<{ token: string }>()

  const [isVerify, setIsVerify] = useState(false)

  const refName = useRef<InputTextRef | null>(null)
  const refPassword = useRef<InputTextRef | null>(null)
  const refEmail = useRef<InputTextRef | null>(null)
  const refCode = useRef<InputTextRef | null>(null)

  const confirm = async () => {
    const name = refName.current?.getValue() || ''
    const password = refPassword.current?.getValue() || ''
    if (!name || (name && !name.trim())) {
      refName.current?.setError('Please enter name')
    }
    if (!password || (password && !password.trim())) {
      refPassword.current?.setError('Please enter password')
    }
    if (name && password) {
      confirmInvite({
        variables: { token: token || '', password, name },
        onSuccess: () => {
          refName.current?.clear()
          refPassword.current?.clear()
          setIsVerify(true)
        },
      })
    }
  }

  const verify = async () => {
    const email = refEmail.current?.getValue() || ''
    const code = refCode.current?.getValue() || ''
    if (!email || (email && !email.trim()) || !email.match(REGEX_EMAIL)) {
      refEmail.current?.setError('Please enter valid email')
    }
    if (!code || (code && !code.trim())) {
      refCode.current?.setError('Please enter code')
    }
    if (email && code && email.match(REGEX_EMAIL)) {
      verifyRegistrationCode({
        variables: { email, code },
        onSuccess: data => {
          console.log('data', data)
        },
      })
    }
  }

  return (
    <div className='h-screen'>
      <div className='background-auth flex items-center justify-center'>
        {!isVerify ? (
          <div className='p-8 bg-white rounded-2xl text-center w-96'>
            <div className='mb-4 text-2xl font-bold'>Confirm your invitation</div>

            <InputText label='Name' ref={refName} />
            <InputText label='Password' type='password' ref={refPassword} />

            <button onClick={confirm} className='mt-6 w-full'>
              Confirm
            </button>
          </div>
        ) : (
          <div className='p-8 bg-white rounded-2xl text-center w-96'>
            <div className='mb-4 text-2xl font-bold'>Verify registration code</div>

            <InputText label='Email' ref={refEmail} />
            <InputText label='Code' ref={refCode} />

            <button onClick={verify} className='mt-6 w-full'>
              Verify
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ConfirmInvite
