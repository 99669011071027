// import AppPagination from '@/components/AppPagination'
import OrganisationTypeChip from '@/components/OrganisationTypeChip'
import SideBar from '@/components/SideBar'
import Tooltip from '@/components/Tooltip'
import TopBar from '@/components/TopBar'
import { FORMAT_TIME } from '@/constants'
import { CreateCommissionTermsParams } from '@/graphql/types'
import useCommissionTerms from '@/hooks/useCommissionTerms'
import useConnections from '@/hooks/useConnections'
import { showAlert } from '@/redux/reducers/common.slice'
import { CommissionTerms, Connection } from '@/types'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CreateCommissionTermsDialog from '../CommissionTerms/components/CreateCommissionTermsDialog'
import ViewCommissionTermsDialog from './components/ViewCommissionTermsDialog'

const Connections: React.FC = () => {
  const dispatch = useDispatch()

  const { getListConnections, refetch } = useConnections()
  const { createCommissionTerms } = useCommissionTerms()

  const [data, setData] = useState<Connection[]>([])
  // const [page, setPage] = useState(1)
  const [commissionTerms, setCommissionTerms] = useState<CommissionTerms[] | undefined>(undefined)
  const [connectionId, setConnectionId] = useState<number | undefined>(undefined)

  useEffect(() => {
    getListConnections({
      onSuccess: response => {
        setData(response.connections)
      },
    })
  }, [])

  const onCloseViewDialog = () => {
    setCommissionTerms(undefined)
  }

  const onViewCommissionTerms = (terms: CommissionTerms[]) => () => {
    setCommissionTerms(terms)
  }

  // const onChangePage = (value: number) => {
  //   setPage(value)
  // }

  const onAddCommissionTerms = (id: number) => () => {
    setConnectionId(id)
  }

  const onCloseCreateDialog = () => {
    setConnectionId(undefined)
  }

  const onCreateCommissionTerms = (params: CreateCommissionTermsParams) => {
    createCommissionTerms({
      variables: params,
      onSuccess: () => {
        onCloseCreateDialog()
        dispatch(showAlert({ message: 'Create Commission Terms successfully', type: 'success' }))
        refetch().then(response => {
          setData(response.data.connections)
        })
      },
    })
  }

  return (
    <div className='w-full h-full bg-gray-100'>
      <TopBar title='Connections' />

      <SideBar />

      <div className='content md:pl-64'>
        <div className='w-full max-w-[1536px] m-8 p-6 rounded-md bg-white'>
          <div className='text-2xl font-semibold mb-6'>List Connections</div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000 }}>
              <TableHead>
                <TableRow sx={{ background: '#F7F7F7' }}>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Index</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }}>Organisation</TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='center'>
                    Type
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Commission Terms
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Created Date
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600, py: 1 }} align='right'>
                    Updated Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length ? (
                  data.map((item, index) => (
                    <TableRow key={item.id}>
                      {/* <TableCell>{(page - 1) * DEFAULT_PAGESIZE + index + 1}</TableCell> */}
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.organisation.name}</TableCell>
                      <TableCell align='center'>
                        <OrganisationTypeChip type={item.organisation.type} />
                      </TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' justifyContent='end' spacing={1}>
                          {!!item.commissionTerms?.length && (
                            <Tooltip title='View'>
                              <VisibilityOutlinedIcon
                                onClick={onViewCommissionTerms(item.commissionTerms)}
                                color='primary'
                                sx={{ cursor: 'pointer' }}
                              />
                            </Tooltip>
                          )}
                          <Tooltip title='Add'>
                            <AddCircleOutlineIcon
                              onClick={onAddCommissionTerms(item.id)}
                              color='primary'
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell align='right'>
                        {moment(item.createdAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                      <TableCell align='right'>
                        {moment(item.updatedAt).format(FORMAT_TIME.YEAR_MONTH_DAY)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className='flex w-full flex-col items-center py-14'>
                        <label className='font-medium'>No data found</label>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* {!!data?.connections.length && (
            <AppPagination totalPage={50} currentPage={page} onChangePage={onChangePage} />
          )} */}
        </div>

        {commissionTerms && (
          <ViewCommissionTermsDialog
            open={!!commissionTerms}
            onClose={onCloseViewDialog}
            data={commissionTerms}
          />
        )}

        <CreateCommissionTermsDialog
          open={!!connectionId}
          onClose={onCloseCreateDialog}
          onCreate={onCreateCommissionTerms}
          listConnections={data}
          connectionId={connectionId}
        />
      </div>
    </div>
  )
}

export default Connections
