import { FORMAT_TIME } from '@/constants'
import moment from 'moment'

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const generateRandomString = (): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let randomString = ''

  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    randomString += characters.charAt(randomIndex)
  }

  return randomString
}

export const formatTimestamp = (timestamp: string) => {
  const inputDate = moment(timestamp)
  const currentDate = moment()

  if (inputDate.isSame(currentDate, 'day')) {
    return inputDate.format(FORMAT_TIME.HOUR_MINUTE)
  }

  if (currentDate.diff(inputDate, 'days') < 7) {
    return inputDate.format(FORMAT_TIME.DATE_HOUR_MINUTE)
  }

  if (currentDate.diff(inputDate, 'days') <= 30) {
    const daysAgo = currentDate.diff(inputDate, 'days')
    return `${daysAgo} days ago`
  }

  if (inputDate.isSame(currentDate, 'year')) {
    const monthsAgo = currentDate.diff(inputDate, 'months')
    return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`
  }

  return inputDate.format(FORMAT_TIME.YEAR_MONTH_DAY)
}
