import React from 'react'
import MessageItem from './MessageItem'

type Props = {
  selectedChatbox: any
}

const listChatMessages = [
  {
    id: 1,
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    senderId: 6,
    timestamp: '2024-05-30T10:01:00Z',
  },
  {
    id: 2,
    content:
      'Fermentum iaculis eu non diam phasellus vestibulum lorem sed. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor. Vel pretium lectus quam id leo',
    senderId: 4,
    timestamp: '2024-05-30T10:01:00Z',
  },
  {
    id: 3,
    content:
      'Iaculis nunc sed augue lacus viverra. Turpis in eu mi bibendum neque egestas. Nibh tellus molestie nunc non blandit. Aliquet nibh praesent tristique magna sit amet purus gravida. Ultricies lacus sed turpis tincidunt id aliquet risus feugiat',
    senderId: 6,
    timestamp: '2024-05-30T10:01:00Z',
  },
  {
    id: 4,
    content:
      'Convallis convallis tellus id interdum velit laoreet id donec ultrices. Consectetur adipiscing elit duis tristique sollicitudin nibh. Mollis aliquam ut porttitor leo a. Erat nam at lectus urna duis convallis convallis. Diam maecenas sed enim ut sem viverra',
    senderId: 4,
    timestamp: '2024-05-28T10:01:00Z',
  },
  {
    id: 5,
    content:
      'Tellus in metus vulputate eu. Tincidunt id aliquet risus feugiat in ante metus. Egestas pretium aenean pharetra magna ac. Lectus urna duis convallis convallis tellus',
    senderId: 6,
    timestamp: '2024-05-29T12:01:00Z',
  },
  {
    id: 6,
    content:
      'Tellus in metus vulputate eu. Tincidunt id aliquet risus feugiat in ante metus. Egestas pretium aenean pharetra magna ac. Lectus urna duis convallis convallis tellus',
    senderId: 6,
    timestamp: '2024-05-30T10:01:00Z',
  },
]

const ChatDetails: React.FC<Props> = ({ selectedChatbox }) => {
  return (
    <div className='h-full flex flex-col'>
      <div className='flex flex-row items-center gap-2 p-4 border-b'>
        <div className='flex-none w-10 h-10 rounded-full border flex items-center justify-center'>
          avatar
        </div>
        <div className='flex-1 text-base font-medium'>{selectedChatbox.interlocutor.name}</div>
      </div>
      <div className='flex-1 overflow-auto pt-2'>
        {listChatMessages.map(message => (
          <MessageItem key={message.id} message={message} />
        ))}
      </div>
      <div className='border-t p-4'>
        <div>input message</div>
      </div>
    </div>
  )
}

export default React.memo(ChatDetails)
