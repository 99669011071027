import AppButton from '@/components/AppButton'
import AppDialog from '@/components/AppDialog'
import AppInput, { AppInputRef } from '@/components/AppInput'
import AppSelect, { AppSelectRef } from '@/components/AppSelect'
import { Contract, SopCompensationTypeEnum } from '@/types'
import Stack from '@mui/material/Stack'
import React, { useRef } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  onUpdate: (unitRate: number, type: SopCompensationTypeEnum, description?: string) => void
  contract: Contract
}

const SetSopCompensationDialog: React.FC<Props> = ({ open, onClose, onUpdate, contract }) => {
  const refUnitRate = useRef<AppInputRef | null>(null)
  const refType = useRef<AppSelectRef | null>(null)
  const refDescription = useRef<AppInputRef | null>(null)

  const handleUpdate = () => {
    const unitRate = refUnitRate.current?.getValue() ? Number(refUnitRate.current?.getValue()) : 0
    const type = refType.current?.getValue() as SopCompensationTypeEnum
    const description = refDescription.current?.getValue()

    if (!unitRate || !type) {
      !unitRate && refUnitRate.current?.setError('Please enter Unit Rate')
      !type && refType.current?.setError('Please select Type')
    } else {
      onUpdate(unitRate, type, description)
    }
  }

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title='Set SOP Compensation'
      ButtonNegative={
        <AppButton onClick={onClose} color='error'>
          Cancel
        </AppButton>
      }
      ButtonPositive={<AppButton onClick={handleUpdate}>Set</AppButton>}
    >
      <Stack spacing={2} my={1}>
        <AppInput
          label='Unit Rate'
          type='number'
          required
          defaultValue={
            contract.sopCompensation?.unitRate ? contract.sopCompensation.unitRate.toString() : ''
          }
          ref={refUnitRate}
        />

        <AppSelect
          list={[
            { value: SopCompensationTypeEnum.VSOP, label: 'VSOP' },
            { value: SopCompensationTypeEnum.ESOP, label: 'ESOP' },
          ]}
          label='Type'
          required
          defaultValue={contract.sopCompensation?.type}
          ref={refType}
        />

        <AppInput
          label='Description'
          multiline
          defaultValue={contract.sopCompensation?.description}
          ref={refDescription}
        />
      </Stack>
    </AppDialog>
  )
}

export default React.memo(SetSopCompensationDialog)
