import { User } from '@/types'
import React from 'react'

type Props = {
  profile: User
}

const UserInfo: React.FC<Props> = ({ profile }) => {
  return (
    <div className='w-60 flex flex-col items-center'>
      <div className='w-32 h-32 rounded-full bg-fuchsia-200' />
      <div className='text-xl font-medium mt-2'>{profile?.name}</div>
      <div className='text-xs text-subtitle mt-2'>{profile?.organisation?.name}</div>

      <div className='w-full p-4 text-left border-y mt-6'>{profile?.email}</div>
      <div className='w-full p-4 text-left border-b'>{profile?.organisation?.type}</div>
      <div className='w-full p-4 text-left border-b'>{profile?.role}</div>
    </div>
  )
}

export default React.memo(UserInfo)
